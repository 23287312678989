/* eslint-disable no-useless-return */
/* eslint-disable no-undef */
'use strict';

/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:userAdventureDetailCtrl
 * @description
 * # userAdventureDetailCtrl
 * Controller of the heliApp
 */

angular.module('heliApp').controller('userAdventureDetailCtrl',['$sce', '$scope', '$rootScope', '$interval', '$stateParams', 'userAdventureDetailService', '$location', '$anchorScroll', '$window', "$state", '$cookies', 'sharedService', 'MESSAGE_HELPER', 'GOOGLE_API_KEY', 'publicFeedsService', '$timeout', 'CONST_HELPER', 'NgMap', '$filter', 'userSocialProfileService', 'rootScopeService', userAdventureDetailCtrl]);

function userAdventureDetailCtrl ($sce, $scope, $rootScope, $interval, $stateParams, userAdventureDetailService, $location, $anchorScroll, $window, $state, $cookies, sharedService, MESSAGE_HELPER, GOOGLE_API_KEY, publicFeedsService, $timeout, CONST_HELPER, NgMap, $filter, userSocialProfileService, rootScopeService) {
  var vm = this;
  $scope.MESSAGE_HELPER = MESSAGE_HELPER;
  vm.adventureDetails = [];
  $rootScope.errorAlert = 'You can not select unavailable dates.';
  $scope.shareSelectedSlot = {};
  // SUB DOMAIN CODE
  var adventurePackage = {
    package_id: $stateParams.package_id,
    referral_id: $stateParams.referral_id,
    userToken: $scope.user.token
  };
  var copiedSlots; // copy of all slots available
  // var featuredImageElement=angular.element(document.getElementById('banner-image'))
  // document.getElementsByTagName('body')[CONST_HELPER.ZERO].classList.remove("fix-scroll")
  $('#navigation-scroll').removeClass('fix-scroll');
  // hide search icon..
  // $('.only-tablet').attr('style','display: none !important');

  // check user is logged in or not...
  vm.isUserLoggedIn = !!$scope.user.token;
  $scope.packageDataNotFound = false;
  $rootScope.disableHeaderBackBtn = false;
  rootScopeService.setValue("disableHeaderBackBtn", $rootScope.disableHeaderBackBtn);
  vm.pageDetails = {};
  vm.activeTab = 'activities';
  vm.selectedSlots = [];
  vm.googleApiKey = GOOGLE_API_KEY;
  vm.galleryOpened = false;
  vm.booking_type = CONST_HELPER.BOOKING_TYPE.NIGHTLY_SLOTS;
  vm.package_location_images = [];
  vm.package_location_videos = [];
  vm.nightly_selected_dates = {};
  var total = CONST_HELPER.ZERO;
  vm.adult = $rootScope.selectedGuest ? String($rootScope.selectedGuest) : '1';
  vm.adventureDetails['single_day_activity'] = CONST_HELPER.ZERO; // setting by default false temp
  vm.adultRange = CONST_HELPER.ONE;
  vm.consumerPrice;
  vm.caseAwithoutLodging = false;
  $scope.slickHeliAdventureConfig = {};
  $scope.minNightlyRateLimitError = false;

  if($('div.k-calendar-view.k-calendar-monthview > table > tbody > tr > td > a').length){
    $('div.k-calendar-view.k-calendar-monthview').remove()
  }

  vm.maxGuest = CONST_HELPER.ZERO;
  var max = CONST_HELPER.ZERO;
  var max_capacity = CONST_HELPER.ZERO;
  vm.noOfAvailableDates = CONST_HELPER.ZERO;
  $timeout(function () { // for custom plugin
    $('.selectpicker').selectpicker();
  }, 1000);
  var disableDates = [];
  var chekoutOnlyDates = [];
  if ($rootScope.localCurrencyCode && $scope.user.role_id !== CONST_HELPER.USER_ROLE.OPERATOR) {
    getAdventureDetails(); // call getAdventureDetails(); initially for user and ambassadar...
  } else if ($scope.user.role_id === CONST_HELPER.USER_ROLE.OPERATOR) {
    $rootScope.localCurrencyCode = '';
    getAdventureDetails(); // call getAdventureDetails(); initially for operator...
  } else {
    $scope.showLoader();
    $rootScope.$on('get-package-addons', function () {
      getAdventureDetails(); // call getAdventureDetails(); initially...
    });
  }

  if ($rootScope.isSubDomain || $rootScope.isAgent) {
    getAdventureDetails();
  }

  Date.prototype.yyyymmdd = function () {
    var mm = this.getMonth() + CONST_HELPER.ONE; // getMonth() is zero-based
    var dd = this.getDate();

    return [this.getFullYear(),
      (mm > CONST_HELPER.NINE ? '' : '0') + mm,
      (dd > CONST_HELPER.NINE ? '' : '0') + dd
    ].join('');
  };
  $scope.checkForEmptyObjectAndArray = function (val) {
    if (val) {
      if ($.isArray(val)) {
        return val.length > CONST_HELPER.ZERO;
      } else {
        return !angular.equals(val, {});
      }
    } else {
      return false;
    }
  };
  $scope.getMaxCapacityByLodgingId = function (id) {
    if (vm.adventureDetails.lodgings && vm.adventureDetails.lodgings.length) {
      return vm.adventureDetails.lodgings.find(function (o) {
        return o.id === id;
      }).max_guests;
    } else {
      return CONST_HELPER.ZERO;
    }
  };
  $scope.getCalendar = function (array, bookingWindow) {
    var dateObj = {
      totalLodgings: array.length
    };
    var endDate = null;
    if (bookingWindow) {
      endDate = moment().add(bookingWindow, 'months').format('YYYY-MM-DD');
    }
    var currentDate = moment(new Date()).format('YYYY-MM-DD');
    array.forEach(function (val, index) {
      if (val.calendar && val.calendar.length > CONST_HELPER.ZERO) {
        if (vm.adventureDetails.package_case == CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE) {
          max_capacity = max_capacity > val.max_capacity ? max_capacity : val.max_capacity;
        } else if (vm.adventureDetails.package_case == CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) {
          var capacity = $scope.getMaxCapacityByLodgingId(val.lodging_id);
          max_capacity = max_capacity < capacity ? capacity : max_capacity;
        }
        if (typeof val.calendar === 'string') {
          val.calendar = JSON.parse(val.calendar);
        }
        val.calendar.forEach(function (date) {
          if (vm.adventureDetails.package_case == CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) {
            var capacity = date.available_room * $scope.getMaxCapacityByLodgingId(val.lodging_id);
            max = max < capacity ? capacity : max;
          } else if (vm.adventureDetails.package_case == CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE) {
            max = max < date.available_seat ? date.available_seat : max;
          }
          var _tmpDate = date.date;
          if (date.date.split('-').length > CONST_HELPER.ONE) {
            date.date = +date.date.split('-')[CONST_HELPER.ZERO] + '/' + (+date.date.split('-')[CONST_HELPER.ONE] - CONST_HELPER.ONE) + '/' + (+date.date.split('-')[CONST_HELPER.TWO]);
          }
          if (dateObj[date.date]) {
            if (vm.adventureDetails.package_case == CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) {
              dateObj[date.date].available_room = parseInt(dateObj[date.date].available_room) + parseInt(date.available_room);
              dateObj[date.date].available_seat = parseInt(dateObj[date.date].available_seat) + parseInt(date.available_seat);
            } else {
              dateObj[date.date].available_room = dateObj[date.date].available_room > date.available_room ? dateObj[date.date].available_room : date.available_room;
              dateObj[date.date].available_seat = dateObj[date.date].available_seat > date.available_seat ? dateObj[date.date].available_seat : date.available_seat;
            }

            dateObj[date.date].is_block = parseInt(+dateObj[date.date].is_block ? 1 : 0) + parseInt(date.is_block);
            if (parseInt(date.price) <= parseInt(dateObj[date.date].price)) {
              dateObj[date.date].price = date.price;
            }
          } else {
            dateObj[date.date] = {
              maxCapacity: $scope.getMaxCapacityByLodgingId(val.lodging_id),
              price: date.price,
              available_seat: date.available_seat,
              // is_block: date.is_block,
              is_block: moment(moment(_tmpDate).format('YYYY-MM-DD')).isAfter(endDate) ? (!date.forceActive) : date.is_block,
              available_room: date.available_room
            };
            if(vm.adventureDetails.daily_rate_activity == 1 && moment(_tmpDate).format('YYYY-MM-DD') == currentDate) {
              dateObj[date.date].is_block = 1;
            }
          }
        });
      }
    });
    return dateObj;
  };

  var minNightlySlot;
  var now = moment().format('YYYY-M-DD');

  function findMinPriceForNightlySlot () {
    minNightlySlot = vm.adventureDetails.nightly_slots.max_price;
      vm.adventureDetails.nightly_slots.nightly_slot_lodgings.forEach(function (lodj) {
        for (var i = 0; i < lodj.calendar.length; ++i) {
          if ((lodj.calendar[i].price < minNightlySlot) && (lodj.calendar[i].is_block == 0) && moment(lodj.calendar[i].start).isAfter(now)) {
            minNightlySlot = lodj.calendar[i].price;
          }
        }
    })
  }

  $scope.updateAdult = function (init) {
    console.log('UPDATE ADULT');
    if (vm.booking_type == CONST_HELPER.BOOKING_TYPE.NIGHTLY_SLOTS) {
      total = CONST_HELPER.ZERO;
      vm.nightly_selected_dates.start = vm.nightly_selected_dates.end = CONST_HELPER.ZERO;

      findMinPriceForNightlySlot();

      if (vm.adventureDetails.single_day_activity != CONST_HELPER.ONE) {
        if (init != 'firstLoad') {
          $scope.destroyDateRangePicker();
          $scope.initializeKendoDatePicker();
        }
        if (vm.adventureDetails.package_case === CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) {
          vm.consumerPrice = (total != CONST_HELPER.ZERO ? total : minNightlySlot) * Math.ceil(+vm.adult / (max_capacity || max));
        } else if (vm.adventureDetails.package_case === CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE) {
          vm.consumerPrice = total != CONST_HELPER.ZERO ? total : minNightlySlot;
        } else {
          vm.consumerPrice = (total != CONST_HELPER.ZERO ? total : minNightlySlot) * +vm.adult;
        }
      } else {
        if (init != 'firstLoad') {
          $scope.destroySingleDateRangePicker();
          $scope.initializeKendoSingleDatePicker();
        }
        if (vm.adventureDetails.package_case === CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) {
          vm.consumerPrice = (total != CONST_HELPER.ZERO ? total : minNightlySlot) * Math.ceil(+vm.adult / (max_capacity || max));
        } else if (vm.adventureDetails.package_case === CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE) {
          vm.consumerPrice = total != CONST_HELPER.ZERO ? total : minNightlySlot;
        } else {
          vm.consumerPrice = (total != CONST_HELPER.ZERO ? total : minNightlySlot) * +vm.adult;
        }
      }
    } else {
      vm.selectedSlots = {};
      vm.adventureDetails.selected_slot = '';
    }
  };

  function convertTempDate (cellDate) {
    var splitDate = cellDate.split('/');
    var tempdateConverted = new Date(splitDate[CONST_HELPER.ZERO], splitDate[CONST_HELPER.ONE], splitDate[CONST_HELPER.TWO]).yyyymmdd();
    return tempdateConverted;
  }

  function setAvailableSeatsInCalendar () {
    disableDates = [];
    $scope.checkoutDatesArray = [];
    $('div.k-calendar-view.k-calendar-monthview > table > tbody > tr > td > a').each(function (idx, elem) {
      var cellDate = $(this).attr('data-value');
      var checkoutDate = false;

      if (vm.calendar.hasOwnProperty(cellDate)) {
        var splitDateNew = cellDate.split('/');
        var cellDateObj = new Date(splitDateNew[CONST_HELPER.ZERO], splitDateNew[CONST_HELPER.ONE], splitDateNew[CONST_HELPER.TWO]);
        cellDateObj.setHours(CONST_HELPER.ZERO, CONST_HELPER.ZERO, CONST_HELPER.ZERO);
        var diffInDays = Math.floor((cellDateObj - new Date().setHours(0, 0, 0, 0)) / (1000 * 60 * 60 * 24)); // get no of days
        if (vm.calendar[cellDate].available_seat || vm.calendar[cellDate].available_room) {
          if (moment(cellDateObj).isSame(vm.adventureDetails.nightly_slots.from_date) || moment(cellDateObj).isSame(vm.adventureDetails.nightly_slots.to_date) || moment(cellDateObj).isBetween(vm.adventureDetails.nightly_slots.from_date, vm.adventureDetails.nightly_slots.to_date) || vm.adventureDetails.booking_window != 0) {
            var price = $filter('currency')(vm.calendar[cellDate].price, $scope.currencySymbol[vm.adventureDetails.currency.code], CONST_HELPER.ZERO);
            if (vm.adventureDetails.daily_rate_activity == 1) {
              if (vm.adventureDetails.package_case === CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) {
                $(this).append('<span>' + vm.calendar[cellDate].available_room + ' Units<br>' + price + '</span>');
              } else {
                $(this).append('<span>' + vm.calendar[cellDate].available_seat + ' Seats<br>' + price + '</span>');
              }
            } else {
              $(this).append('<span>' + price + '</span>');
            }
          }
        } else {
          $(this).append('<span>Sold Out</span>');
        }
        var splitDate = cellDate.split('/');
        var tempdate = new Date(splitDate[CONST_HELPER.ZERO], splitDate[CONST_HELPER.ONE], splitDate[CONST_HELPER.TWO]).yyyymmdd();

        if (
          vm.calendar[cellDate].is_block
          ||
          diffInDays < vm.adventureDetails.nightly_slots.advance_notice_days
          ||
          !(
            vm.calendar[cellDate].available_seat
            ||
            vm.calendar[cellDate].available_room
          )
          ||
          !(
            moment(cellDateObj).isSame(vm.adventureDetails.nightly_slots.from_date)
            ||
            moment(cellDateObj).isSame(vm.adventureDetails.nightly_slots.to_date)
            ||
            moment(cellDateObj).isBetween(vm.adventureDetails.nightly_slots.from_date, vm.adventureDetails.nightly_slots.to_date)
            ||
            vm.adventureDetails.booking_window != 0
          )
        ) {
          var prevDate = moment(cellDate).subtract(1, 'days').format('YYYY/M/D');
          if (
            !vm.adventureDetails.daily_rate_activity
            &&
            vm.calendar[prevDate]
            &&
            (
              !vm.calendar[prevDate].is_block
              &&
              (
                vm.calendar[prevDate].available_seat
                ||
                vm.calendar[prevDate].available_room
              )
            )
            &&
            vm.calendar[cellDate]
            &&
            (
              vm.calendar[cellDate].is_block
              ||
              !(
                vm.calendar[cellDate].available_seat
                ||
                vm.calendar[cellDate].available_room
              )
            )
          ) {
            $scope.checkoutDatesArray.push(cellDate);
            $(this).parent().removeClass('k-state-disabled');
            $(this).find('span').remove();
            $(this).append('<span>Checkout<br>Only</span>');
          } else if ($(this).parent().hasClass('k-state-disabled')) {
            $(this).find('span').remove();
            disableDates.push(tempdate);
          }
        } else {
          if (
            (
              vm.adventureDetails.package_case === CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION
              &&
              vm.calendar[cellDate].available_room * vm.calendar[cellDate].maxCapacity < +vm.adult
            )
            ||
            (
              vm.adventureDetails.package_case !== CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION
              &&
              +vm.calendar[cellDate].available_seat < +vm.adult
            )
          ) {
            if ($(this).parent().hasClass('k-state-disabled')) {
              $(this).find('span').remove();
              disableDates.push(tempdate);
            }
          } else {
            $(this).parent().removeClass('k-state-disabled');
          }
        }
      }
    });
  }

  function setAvailableSeatsInSingleCalendar () {
    disableDates = [];
    $('div.k-calendar-view > table > tbody > tr > td > a').each(function (idx, elem) {
      var cellDate = $(this).attr('data-value');

      if (vm.calendar.hasOwnProperty(cellDate)) {
        var cellDateObj = new Date(cellDate);
        cellDateObj.setHours(CONST_HELPER.ZERO, CONST_HELPER.ZERO, CONST_HELPER.ZERO);
        cellDateObj.setMonth(cellDateObj.getMonth() + CONST_HELPER.ONE);
        var diffInDays = Math.floor((cellDateObj - new Date().setHours(0, 0, 0, 0)) / (1000 * 60 * 60 * 24)); // get no of days
        if (vm.calendar[cellDate].available_seat || vm.calendar[cellDate].available_room) {
          var price = $filter('currency')(vm.calendar[cellDate].price, $scope.currencySymbol[vm.adventureDetails.currency.code], CONST_HELPER.ZERO);
          if (vm.adventureDetails.package_case === CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) {
            $(this).append('<span>' + vm.calendar[cellDate].available_room + ' Units<br>' + price + '</span>');
          } else {
            $(this).append('<span>' + vm.calendar[cellDate].available_seat + ' Seats<br>' + price + '</span>');
          }
        } else {
          $(this).append('<span>Sold Out</span>');
        }
        var splitDate = cellDate.split('/');
        var tempdate = new Date(splitDate[CONST_HELPER.ZERO], splitDate[CONST_HELPER.ONE], splitDate[CONST_HELPER.TWO]).yyyymmdd();
        if (vm.calendar.totalLodgings == vm.calendar[cellDate].is_block || diffInDays < vm.adventureDetails.nightly_slots.advance_notice_days || !(vm.calendar[cellDate].available_seat || vm.calendar[cellDate].available_room)) {
          disableDates.push(tempdate);
        } else {
          if (vm.adventureDetails.package_case === CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION && vm.calendar[cellDate].available_room * vm.calendar[cellDate].maxCapacity < +vm.adult) {
            disableDates.push(tempdate);
          } else if (vm.adventureDetails.package_case !== CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION && +vm.calendar[cellDate].available_seat < +vm.adult) {
            disableDates.push(tempdate);
          } else {
            $(this).parent().removeClass('k-state-disabled');
          }
        }
      }
    });
  }

  function destroyNightlyCalendar () {
    var inputs = $('#daterangepicker span span input');
    inputs[CONST_HELPER.ZERO].value = 'Check In';
    inputs[CONST_HELPER.ONE].value = 'Check Out';
    vm.nightly_selected_dates = {};
    $scope.$apply();
    $scope.showPopup('#AlertModal');
    return;
  }

  // render calendar title function
  $scope.renderCalendarTitle = function () {
    var text = '';
    var tempDate = $('div.k-calendar-view.k-calendar-monthview > table > tbody > tr > td > a:eq(15)')[CONST_HELPER.ZERO].dataset.value.split('/');
    var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    text = months[tempDate[CONST_HELPER.ONE]] + ' ' + tempDate[CONST_HELPER.ZERO];
    $('div.k-calendar-header > a').html(text);
    $('div.k-calendar-header > a').css('display', 'block');
  };
  function monthDiff (dateFrom, dateTo) {
    return dateTo.getMonth() - dateFrom.getMonth() +
      (12 * (dateTo.getFullYear() - dateFrom.getFullYear()));
  }
  // function for intializing datepicker methods and attaching event handlers
  $scope.initializeKendoDatePicker = function () {
    var firstRender = true;
    var storeRange = {}; // storing range handling manual null trigger
    var isValid = false;
    var start = new Date((+vm.adventureDetails.nightly_slots.from_date.split('-')[CONST_HELPER.ZERO]), (+vm.adventureDetails.nightly_slots.from_date.split('-')[CONST_HELPER.ONE] - CONST_HELPER.ONE), CONST_HELPER.ONE);
    var end = new Date((+vm.adventureDetails.nightly_slots.to_date.split('-')[CONST_HELPER.ZERO]), (+vm.adventureDetails.nightly_slots.to_date.split('-')[CONST_HELPER.ONE] + CONST_HELPER.ONE), CONST_HELPER.ZERO);

    var diffInMonths = monthDiff(new Date(), start);
    function onOpen () {
      $scope.minNightlyRateLimitError = false;
      $scope.renderCalendarTitle();
      $('.k-button.k-title').click(false);
      $('.k-calendar-nav').click(function (e) {
        $('div.k-calendar-header > a').css('display', 'none');
        $timeout(function () {
          $('div.k-calendar-view.k-calendar-monthview > table > tbody > tr > td > a').each(function (idx, elem) {
            $(this).children().remove();
          });
          setAvailableSeatsInCalendar();
          $scope.renderCalendarTitle();
        }, CONST_HELPER.ZERO);
      });
      if (firstRender) {
        $timeout(function () {
          var i = 1;
          while (i <= diffInMonths) {
            $('div.k-calendar-header > span > a.k-button.k-button-icon.k-next-view').trigger('click');
            i++;
          }
        }, CONST_HELPER.ZERO);
      }

      setAvailableSeatsInCalendar();
      firstRender = false;
      $('.k-calendar-view').find('.k-today').removeClass('k-today');
    }

    function onClose (e) {
      $('div.k-calendar-header > a').css('display', 'none');
      $('div.k-calendar-view.k-calendar-monthview > table > tbody > tr > td > a').each(function (idx, elem) {
        $(this).children().remove();
      });
      $scope.errorBookingSelectedRange = false;
      $scope.errorBookingSelectedRangeMaximum = false;
      $scope.errorBookingSelectedRangeMinimum = false;
      $scope.minNightlyRateLimitError = false;
    }

    function onChange (e) {
      var range = this.range();
      var checkDate = moment(range.start).format('YYYYMMDD').substr(0, 4) + '/' + (+moment(range.start).format('YYYYMMDD').substr(4, 2) - 1) + '/' + +moment(range.start).format('YYYYMMDD').substr(6, 2);

      if ($scope.checkoutDatesArray.includes(moment(checkDate).format('YYYY/M/D')) && !vm.adventureDetails.daily_rate_activity) {
        storeRange = {};
        range = {};
        destroyNightlyCalendar();
      }

      if (range.start && range.end) {
        if (!vm.adventureDetails.daily_rate_activity) {
          var startDate = range.start;
          while (moment(startDate).isSameOrBefore(moment(range.end))) {
            var validationTempData = moment(startDate).format('YYYYMMDD').substr(0, 4) + '/' + (+moment(startDate).format('YYYYMMDD').substr(4, 2) - 1) + '/' + +moment(startDate).format('YYYYMMDD').substr(6, 2);
            if ($scope.checkoutDatesArray.includes(moment(validationTempData).format('YYYY/M/D')) && !moment(startDate).isSame(moment(range.end))) {
              storeRange = {};
              range = {};
              destroyNightlyCalendar();
            }
            startDate = moment(startDate).add(1, 'days').format('YYYYMMDD');
          }
        }
        storeRange.start = range.start;
        storeRange.end = range.end;
        angular.copy(range, vm.nightly_selected_dates);
        $scope.$apply();
        isValid = true;
      } else if (range.start === null && range.end === null) {
        if (isValid) {
          var flag = true;
          var allSelectedDates = $scope.getAllInBetweenDays(storeRange.start, storeRange.end);
          var formattedDates = $scope.convertToYYYYMD(allSelectedDates);
          disableDates.forEach(function (date) {
            if (allSelectedDates.includes(date)) {
              $scope.showPopup('#AlertModal');
              flag = false;
              isValid = false;
              var inputs = $('#daterangepicker span span input');
              if (vm.booking_type == 2 && vm.adventureDetails.daily_rate_activity == 1) {
                inputs[CONST_HELPER.ZERO].value = 'Start date';
                inputs[CONST_HELPER.ONE].value = 'End date';
              }else{
                inputs[CONST_HELPER.ZERO].value = 'Check In';
                inputs[CONST_HELPER.ONE].value = 'Check Out';
              }
              return;
            }
          });

          var checkoutDatesCheck = [];
          chekoutOnlyDates.forEach(function (date) {
            if (allSelectedDates.includes(date)) {
              checkoutDatesCheck.push(date);
              if (checkoutDatesCheck.length >= 2) {
                $scope.showPopup('#AlertModal');
                flag = false;
                isValid = false;
                var inputs = $('#daterangepicker span span input');
                if (vm.booking_type == 2 && vm.adventureDetails.daily_rate_activity == 1) {
                  inputs[CONST_HELPER.ZERO].value = 'Start date';
                  inputs[CONST_HELPER.ONE].value = 'End date';
                }else{
                  inputs[CONST_HELPER.ZERO].value = 'Check In';
                  inputs[CONST_HELPER.ONE].value = 'Check Out';
                }
                return;
              }
            }
          });

          if (flag) {
            this.range(storeRange);
            angular.copy(storeRange, vm.nightly_selected_dates);
            total = CONST_HELPER.ZERO;
            formattedDates.forEach(function (val) {
              if (vm.calendar[val]) {
                total = total + +vm.calendar[val].price;
              }
            });

            if (vm.adventureDetails.booking_type == CONST_HELPER.TWO && vm.adventureDetails.daily_rate_activity != CONST_HELPER.ONE && vm.adventureDetails.package_case != CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) {
              var lengthArray = formattedDates.length;
              var dayObject = vm.calendar[formattedDates[lengthArray - 1]];
              if (dayObject && dayObject.price) {
                total = total - +dayObject.price;
              }
            }
            if (formattedDates.length >= CONST_HELPER.TWO && vm.adventureDetails.package_case === CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION && vm.adventureDetails.daily_rate_activity == 0) {
                total = total - +vm.calendar[formattedDates[Object.keys(formattedDates).length - CONST_HELPER.ONE]].price;
            }
            if (vm.adventureDetails.package_case === CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE || vm.adventureDetails.package_case === CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) {
              if (vm.adventureDetails.package_case === CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE) {
                if (formattedDates.length === 1) {
                  vm.consumerPrice = vm.calendar[formattedDates[0]] ? vm.calendar[formattedDates[0]].price : vm.adventureDetails.nightly_slots.min_price;
                } else {
                  vm.consumerPrice = total;
                }
              } else {
                vm.consumerPrice = total * Math.ceil(+vm.adult / (max_capacity || max));
              }
            } else {
              vm.consumerPrice = total * +vm.adult;
            }
            $scope.$apply();
            storeRange = {
              start: null,
              end: null
            };
          }
        } else {
          var inputs = $('#daterangepicker span span input');
          if (vm.booking_type == 2 && vm.adventureDetails.daily_rate_activity == 1) {
            inputs[CONST_HELPER.ZERO].value = 'Start date';
            inputs[CONST_HELPER.ONE].value = 'End date';
          } else {
            inputs[CONST_HELPER.ZERO].value = 'Check In';
            inputs[CONST_HELPER.ONE].value = 'Check Out';
          }
        }
      }

      var inputs = $('#daterangepicker span span input');
      if (inputs[CONST_HELPER.ZERO].value == 'day of the week day month' && inputs[CONST_HELPER.ZERO].value == 'day of the week day month') {
        if (vm.booking_type == 2 && vm.adventureDetails.daily_rate_activity == 1) {
          inputs[CONST_HELPER.ZERO].value = 'Start date';
          inputs[CONST_HELPER.ONE].value = 'End date';
        } else {
          inputs[CONST_HELPER.ZERO].value = 'Check In';
          inputs[CONST_HELPER.ONE].value = 'Check Out';
        }
      }

      var startDate = moment(range.start);
      var endDate = moment(range.end);
      var diffDays;
      if (vm.adventureDetails.daily_rate_activity == 1 && vm.adventureDetails.booking_type == 2 && !vm.adventureDetails.single_day_activity) {
        diffDays = endDate.diff(startDate, 'days') + 1;
      } else if (!vm.adventureDetails.daily_rate_activity && vm.adventureDetails.booking_type == 2 && !vm.adventureDetails.single_day_activity) {
        diffDays = endDate.diff(startDate, 'days');
      }

      if(vm.adventureDetails.booking_limit == 1 && range.start && range.end){
        if(vm.adventureDetails.booking_minimum_days && vm.adventureDetails.booking_maximum_days && (diffDays < vm.adventureDetails.booking_minimum_days || diffDays > vm.adventureDetails.booking_maximum_days)){
          $scope.errorBookingSelectedRange = true;
        } else if (!vm.adventureDetails.booking_minimum_days && vm.adventureDetails.booking_maximum_days && diffDays > vm.adventureDetails.booking_maximum_days) {
          $scope.errorBookingSelectedRangeMaximum = true;
        } else if (vm.adventureDetails.booking_minimum_days && !vm.adventureDetails.booking_maximum_days && diffDays < vm.adventureDetails.booking_minimum_days) {
          $scope.errorBookingSelectedRangeMinimum = true;
        }

        if ($scope.errorBookingSelectedRange || $scope.errorBookingSelectedRangeMinimum || $scope.errorBookingSelectedRangeMaximum) {
          storeRange = {
            start: null,
            end: null
          };
          range = {
            start: null,
            end: null
          };
        }
      }

      if(diffDays < 1 && !vm.adventureDetails.daily_rate_activity && vm.adventureDetails.booking_type == 2 && !vm.adventureDetails.single_day_activity){
        storeRange = {
          start: null,
          end: null
        };
        range = {
          start: null,
          end: null
        };
        $scope.minNightlyRateLimitError = true;
      }
    }

    $('#daterangepicker').kendoDateRangePicker({
      change: onChange,
      close: onClose,
      open: onOpen,
      format: 'ddd dd MMM',
      range: [start, end],
      depth: 'month',
      start: 'month',
      dateInput: true,
      disableDates: function (date) {
        return true;
      }
    });
    var datePickerOptions = $('#daterangepicker').data('kendoDateRangePicker').dateView.popup.options;
    datePickerOptions.origin = 'bottom center';
    datePickerOptions.position = 'top center';
    var inputs = $('#daterangepicker span span input');
    if (vm.booking_type == 2 && vm.adventureDetails.daily_rate_activity == 1) {
      inputs[CONST_HELPER.ZERO].value = 'Start date';
      inputs[CONST_HELPER.ONE].value = 'End date';
    } else {
      inputs[CONST_HELPER.ZERO].value = 'Check In';
      inputs[CONST_HELPER.ONE].value = 'Check Out';
    }

    $('#daterangepicker span span input').eq(CONST_HELPER.ZERO).attr('readonly', 'readonly');
    $('#daterangepicker span span input').eq(CONST_HELPER.ONE).attr('readonly', 'readonly');
  };

  $scope.convertToYYYYMD = function (array) {
    return array.map(function (val) {
      return val.substr(0, 4) + '/' + (+val.substr(4, 2) - CONST_HELPER.ONE) + '/' + +val.substr(6, 2);
    });
  };
  $scope.addEventHandlerForCells = function () {
    $(' div.k-calendar-view > table > tbody > tr > td > a').click(function () {
      $('.k-animation-container').hide();
      var text = '';
      if (vm.adventureDetails.package_case === CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) {
        vm.consumerPrice = Math.ceil(+vm.adult / (max_capacity || max)) * +vm.calendar[$(this)[CONST_HELPER.ZERO].dataset.value].price;
      } else if (vm.adventureDetails.package_case === CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE) {
        vm.consumerPrice = +vm.calendar[$(this)[CONST_HELPER.ZERO].dataset.value].price;
      } else {
        vm.consumerPrice = +vm.adult * +vm.calendar[$(this)[CONST_HELPER.ZERO].dataset.value].price;
      }
      var tempDate = $(this)[CONST_HELPER.ZERO].dataset.value.split('/');
      var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      text = tempDate[CONST_HELPER.TWO] + ' ' + months[tempDate[CONST_HELPER.ONE]] + ' ' + tempDate[CONST_HELPER.ZERO];
      $('#datepicker').val(text);
      vm.nightly_selected_dates.start = new Date(text);
      vm.nightly_selected_dates.end = new Date(text);
      $timeout(function () {
        $(' div.k-calendar-view > table > tbody > tr > td > a').each(function (idx, elem) {
          $(this).children().remove();
        });
      }, CONST_HELPER.ZERO);
    });
  };
  $scope.initializeKendoSingleDatePicker = function () {
    var firstRender = true;

    var start = new Date((+vm.adventureDetails.nightly_slots.from_date.split('-')[CONST_HELPER.ZERO]), (+vm.adventureDetails.nightly_slots.from_date.split('-')[CONST_HELPER.ONE] - CONST_HELPER.ONE), CONST_HELPER.ONE);
    var end = new Date((+vm.adventureDetails.nightly_slots.to_date.split('-')[CONST_HELPER.ZERO]), (+vm.adventureDetails.nightly_slots.to_date.split('-')[CONST_HELPER.ONE] + CONST_HELPER.ONE), CONST_HELPER.ZERO);
    var diffInMonths = monthDiff(new Date(), start);

    function onOpen () {
      $('.k-widget.k-calendar').addClass('k-calendar-range k-calendar-single');
      $scope.addEventHandlerForCells();
      $('a.k-link.k-nav-prev').click(function (e) {
        $timeout(function () {
          $('div.k-calendar-view > table > tbody > tr > td > a').each(function (idx, elem) {
            $(this).children().remove();
          });
          setAvailableSeatsInSingleCalendar();
          $scope.addEventHandlerForCells();
        }, 800);
      });
      $('a.k-link.k-nav-next').click(function (e) {
        $timeout(function () {
          $('div.k-calendar-view > table > tbody > tr > td > a').each(function (idx, elem) {
            $(this).children().remove();
          });
          setAvailableSeatsInSingleCalendar();
          $scope.addEventHandlerForCells();
        }, 800);
      });
      setAvailableSeatsInSingleCalendar();
      if (firstRender) {
        $timeout(function () {
          var i = 1;
          while (i <= diffInMonths) {
            $('div.k-header > a.k-link.k-nav-next').trigger('click');
            i++;
          }
        }, CONST_HELPER.ZERO);
      }
      firstRender = false;
      $('.k-calendar-view').find('.k-today').removeClass('k-today');
    }

    function onChange () {

    }

    function onClose (e) {
      $(' div.k-calendar-view > table > tbody > tr > td > a').each(function (idx, elem) {
        $(this).children().remove();
      });
    }
    $('k-animation-container').remove();

    $('#datepicker').kendoDatePicker({
      change: onChange,
      close: onClose,
      open: onOpen,
      format: 'ddd dd MMM',
      range: [start, end],
      // min: start,
      depth: 'month',
      start: 'month',
      // max: end,
      dateInput: true,
      disableDates: function (date) {
        return true;
      }
    });
    var datePickerOptions = $('#datepicker').data('kendoDatePicker').dateView.popup.options;
    datePickerOptions.origin = 'bottom center';
    datePickerOptions.position = 'top center';
    $('#datepicker').val('Select your date');
    $('#datepicker').attr('readonly', 'readonly');
    $('#datepicker').click(function () {
      $('.k-animation-container').show();
    });
  };
  $scope.destroySingleDateRangePicker = function () {
    if ($('#datepicker').data('kendoDatePicker')) {
      $('#datepicker').data('kendoDatePicker').destroy();
      $('#demo > div.package-booking-bar > div.package-side-advent > div.package-check-links > a').empty();
      $('#demo > div.package-booking-bar > div.package-side-advent > div.package-check-links > a').html('<input ng-if="vm.adventureDetails.single_day_activity == 1" id="datepicker" />');
    }
  };

  // function for destroying datepicker
  $scope.destroyDateRangePicker = function () {
    if ($('#daterangepicker').data('kendoDateRangePicker')) {
      $('#daterangepicker').data('kendoDateRangePicker').destroy();
      $('div#daterangepicker').empty();
    }
  };
  // function for getting all dates between date range
  $scope.getAllInBetweenDays = function (start, end) {
    start = new Date(start).getTime();
    end = new Date(end).getTime();
    var oneDay = 24 * 3600 * 1000;
    for (var d = [], incrementOp = start * CONST_HELPER.ONE, end = end * CONST_HELPER.ONE; incrementOp <= end; incrementOp += oneDay) {
      d.push(new Date(incrementOp).yyyymmdd());
    }
    return d;
  };

  // if user logout then reget the data
  $rootScope.$on('user.logout', function () {
    adventurePackage.userToken = '';
    getAdventureDetails('Not-Increse-Hit');
  });

  function getAdventureDetails (increseHit) {
    $scope.showLoader();
    userAdventureDetailService.getAdventureDetails(adventurePackage).then(function (res) {
      // $rootScope.closeMyNav()
      $scope.hideLoader();
      if (res.data && res.data.code === CONST_HELPER.API_RESPONSE.OK) {
        if (res.data.package.package_type == 2 && $rootScope.currentState.name === 'adventure-detail') {
          $('head').prepend('<meta name="robots" content="noindex" />');
          $scope.packageDataNotFound = true;
          $rootScope.disableHeaderBackBtn = true;
          rootScopeService.setValue("disableHeaderBackBtn", $rootScope.disableHeaderBackBtn);
          $timeout(function () {
            $rootScope.closeMyNav();
          }, 0);
          return;
        }
        // add NOINDEX meta tag in head in case private adventure...
        if (res.data.package.package_type == 2 && $rootScope.currentState.name === 'adventure-detail-private') {
          $('head').prepend('<meta name="robots" content="noindex" />');
        }
        if ($scope.user.role_id != 3 && (res.data.package.operator_status === CONST_HELPER.ZERO || res.data.package.status == CONST_HELPER.PACKAGE.DEACTIVATED)) {
          $state.go('link-expire');
          return;
        }
        if ($scope.user.role_id === CONST_HELPER.USER_ROLE.OPERATOR) {
          $rootScope.localCurrencyCode = res.data.package.currency.code;
        }
        // if user redirect to signin by clicking on add to radar button after login add that package to user radar automatically..
        var radrId = $cookies.get('addToRadarPackageId');
        if (radrId !== 'null' && radrId) {
          addToUserRadarList(adventurePackage);
        }
        vm.adventureDetails = res.data.package;
        $rootScope.operatorAdventureCurrencyCode = vm.adventureDetails.currency.code;
        vm.adventureDetails.latitude = parseFloat(vm.adventureDetails.latitude);
        vm.adventureDetails.longitude = parseFloat(vm.adventureDetails.longitude);
        vm.booking_type = vm.adventureDetails.booking_type;
        vm.caseAwithoutLodging = vm.adventureDetails.package_case == CONST_HELPER.PACKAGE_CASE.PER_SEAT && vm.adventureDetails.lodgings.length == CONST_HELPER.ZERO;
        var featuredImageElement = angular.element(document.getElementById('banner-image'));
        getFeaturedImage(res.data.package.package_medias, featuredImageElement);
        $scope.sortDiscountArray(vm.adventureDetails.package_discounts); // sort the package discount array
        vm.currentLodging = vm.adventureDetails.lodgings.length === CONST_HELPER.ONE ? vm.adventureDetails.lodgings[CONST_HELPER.ZERO] : ''; // set currentLodging if only slot is available...
        if (vm.adventureDetails.package_case === CONST_HELPER.TWO && vm.currentLodging) { // set the current lodging if only one lodging is there.
          setCurrentSlotLodging();
        }
        // $scope.checkExpiredSlots([res.data.package]);// check for expired slots...
        if (vm.booking_type == CONST_HELPER.BOOKING_TYPE.NIGHTLY_SLOTS && $scope.checkForEmptyObjectAndArray(vm.adventureDetails.nightly_slots)) {
          vm.adventureDetails.nightly_slots.nightly_slot_lodgings.forEach(function (lodging) {
            lodging.calendar = JSON.parse(lodging.calendar) || lodging.calendar;
            if (lodging.calendar && lodging.calendar.length > CONST_HELPER.ZERO) {
              lodging.calendar = lodging.calendar.filter(function (event) {
                var date = moment(event.date).format('YYYY-MM-DD');
                return moment(date).isSameOrAfter(moment().format('YYYY-MM-DD'));
              });
            }
          });
          var bookingWindow = vm.adventureDetails.nightly_slots.booking_window;
          vm.calendar = $scope.getCalendar(vm.adventureDetails.nightly_slots.nightly_slot_lodgings, bookingWindow);
          vm.noOfAvailableDates = vm.adventureDetails.nightly_slots.total_nightly_available_days;
          if (vm.adventureDetails.package_case == CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) {
            $scope.adultRange = max;
          } else if (vm.adventureDetails.package_case == CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE) {
            $scope.adultRange = max;
          } else {
            $scope.adultRange = vm.adventureDetails.nightly_slots.per_day_available_seat;
          }
        } else {
          if (vm.adventureDetails.package_case == CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) {
            vm.setMaxGuestCapacityForSlots();
          }
          vm.adventureDetails.package_slots.forEach(function (slot) {
            if(moment(slot.from_date).isSameOrBefore(moment(new Date()))){
              slot.expired = 1;
            }
          })
          $scope.adultRange = vm.getMaxAvailablePackageSlots();
        }
        $timeout(function () {
          $('.adult-selectpicker').selectpicker('refresh');
        }, CONST_HELPER.ZERO);
        // get the package currency rate of package currency vs local currency...
        $scope.getSlotUsdRate(vm.adventureDetails.currency.code, $rootScope.localCurrencyCode, function (currencyRate) {
          $scope.localCurencyRate = currencyRate;
        });

        copiedSlots = angular.copy(vm.adventureDetails.package_slots);
        // merge location media if package is associated with location...
        if (vm.adventureDetails.op_location && vm.adventureDetails.op_location.hasOwnProperty('op_location_medias')) {
          var packageMedias = res.data.package.package_medias.concat(vm.adventureDetails.op_location.op_location_medias).concat(vm.adventureDetails.op_location.op_location_videos);
        } else {
          var packageMedias = res.data.package.package_medias;
        }
        if (vm.booking_type == CONST_HELPER.BOOKING_TYPE.NIGHTLY_SLOTS && $scope.checkForEmptyObjectAndArray(vm.adventureDetails.nightly_slots)) {
          setTimeout(function () {
            if ((vm.adventureDetails.single_day_activity != CONST_HELPER.ONE)) { // rendering respective calendar in case of single day booking or range booking
              $scope.destroyDateRangePicker(); // destroying datepicker
              $scope.initializeKendoDatePicker();
            } else {
              $scope.destroySingleDateRangePicker(); // destroying datepicker
              $scope.initializeKendoSingleDatePicker();
            }
          }, CONST_HELPER.ZERO);
          vm.consumerPrice = vm.adventureDetails.nightly_slots.min_price;
          setTimeout(function () {
            $scope.updateAdult('firstLoad');
          }, CONST_HELPER.ZERO);
        }
        adventureVideoFirst(packageMedias); // make adventure video position first...
        vm.isUserLoggedIn = !!$scope.user.token;
        if (increseHit !== 'Not-Increse-Hit') {
          userAdventureDetailService.increaseHits(adventurePackage);
        }
      }
      console.log(vm.adventureDetails.id);
      var data = {
        id: vm.adventureDetails.id ? vm.adventureDetails.id.toString() : '',
        name: vm.adventureDetails.name,
        operator_name: vm.adventureDetails.operator_details.business_name,
        gtmLabel: 'Package View',
        actionField: {
          'step': 1,
          'option': 'Heli Package View'
        },
        event_type: 'packageView',
        event: 'gtm.heli.packageview'
      };

      $rootScope.$broadcast('addEventToGTM', data);
      // $rootScope.$broadcast('addEventToGA',data);

      // Enhanced Ecommerce
      var ecData = {
        'id': vm.adventureDetails.id,
        'name': vm.adventureDetails.name,
        'category': CONST_HELPER.PACKAGE_CASE_NAME[vm.adventureDetails.package_case],
        'brand': vm.adventureDetails.operator_details.business_name,
        'variant': vm.adventureDetails.location_name,
        'event_type': 'packageView'
      };
      $rootScope.$broadcast('addEventToEC', ecData);
      if (increseHit !== 'Not-Increse-Hit') {
        rootScopeService.updateMetaTitle(vm.adventureDetails.operator_details.business_name + ' - ' + vm.adventureDetails.name); // set dynamic title and description of the page.
        rootScopeService.updateMetaDescription(vm.adventureDetails.description);
        rootScopeService.updateMetaImage(vm.adventureDetails.featuredimage);
        rootScopeService.updateMetaUrl(vm.adventureDetails.short_url);
      }
      if ($stateParams.slot_id) { // if slot is in stateparams select that slot ...
        vm.selectedSlots = vm.adventureDetails.package_slots.find(function (slot) {
          return slot.id === +$stateParams.slot_id;
        });
        vm.adventureDetails.selected_slot = 'slot' + vm.selectedSlots.id;
        previousSlot = vm.adventureDetails.selected_slot;
      }

      // check if operator name and package name matched with pakcge url...
      var operatorName = $scope.getSeoFriendlyURL(vm.adventureDetails.operator_details.business_name);
      var packageName = $scope.getSeoFriendlyURL(vm.adventureDetails.name);
      if (!$rootScope.isSubDomain) {
        if ($stateParams.operatorname !== operatorName || $stateParams.packagename !== packageName) {
          $stateParams.operatorname = operatorName;
          $stateParams.packagename = packageName;
        }
      }
      window.prerenderReady = true;
      $rootScope.prerenderReady = window.prerenderReady;
    }, function (err) {
      if (String(err.data.code) === CONST_HELPER.API_RESPONSE.NOT_FOUND) {
        $scope.packageDataNotFound = true;
        $rootScope.disableHeaderBackBtn = true;
        rootScopeService.setValue("disableHeaderBackBtn", $rootScope.disableHeaderBackBtn);
        // $rootScope.closeMyNav()
      } else {
        $scope.showPopup('#serverErrModal');
      }
      $scope.hideLoader();
    });
  }

  // get the featured image from package media
  function getFeaturedImage (media, element) {
    console.log('GET FEATURED IMAGE');
    var featuredimage;
    if (media) {
      media.map(function (obj) { // GET THE FEATURED IMAGE URL OF PACKAGE
        if (obj.media_type === CONST_HELPER.MEDIA.IMAGE && obj.is_main === CONST_HELPER.MEDIA.FEATURED_IMAGE) {
          featuredimage = obj.url;
        }
      });

      if (element[CONST_HELPER.ZERO]) {
        element[CONST_HELPER.ZERO].style.backgroundImage = 'url(' + featuredimage + ')';
      }
      vm.adventureDetails.featuredimage = featuredimage;
    }
  }
  // FUNCTION TO CONCAT THE VIDEOS FIRST IN THE GALLERY
  function adventureVideoFirst (packageMedias) {
    var packageVideos = [];
    var packageImages = [];
    packageMedias.map(function (media, _key) {
      if (media.media_type === CONST_HELPER.ONE) {
        packageVideos.push(media);
      } else {
        packageImages.push(media);
      }
    });
    vm.adventureDetails.package_medias = packageVideos.concat(packageImages);
    vm.package_location_images = packageImages;
    vm.package_location_videos = packageVideos;
    vm.featuredVideo = packageVideos.length ? packageVideos[CONST_HELPER.ZERO] : '';
    // RE INIT SLICK
    $scope.slickHeliAdventureConfig = {};
    $scope.slickHeliLodgingConfig = {};
    setTimeout(function () {
      $scope.slickHeliAdventureConfig = {
        enabled: true,
        autoplay: false,
        draggable: false,
        arrows: true,
        centerPadding: '250px',
        slidesToShow: 1,
        centerMode: true,
        adaptiveHeight: true
      };
      $scope.slickHeliLodgingConfig = {
        enabled: true,
        autoplay: false,
        draggable: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        event: {
          beforeChange: function (_event, _slick, _currentSlide, nextSlide) {
            _slick.$nextArrow[0].style.pointerEvents = "none";
            _slick.$prevArrow[0].style.pointerEvents = "none";
            // code for handling nightly slots on slide change
            if (vm.booking_type == CONST_HELPER.BOOKING_TYPE.NIGHTLY_SLOTS && $scope.checkForEmptyObjectAndArray(vm.adventureDetails.nightly_slots)) {
              if (nextSlide === CONST_HELPER.ZERO) {
                vm.calendar = $scope.getCalendar(vm.adventureDetails.nightly_slots.nightly_slot_lodgings);
                vm.consumerPrice = minNightlySlot;
              } else {
                vm.calendar = $scope.getCalendar([vm.adventureDetails.nightly_slots.nightly_slot_lodgings[nextSlide - CONST_HELPER.ONE]]);
                var currentLodge = vm.adventureDetails.nightly_slots.nightly_slot_lodgings[nextSlide - CONST_HELPER.ONE];
                var minNightlySlotCurrentPrice = currentLodge.price;
                for (var i = 0; i < currentLodge.calendar.length; ++i) {
                  if ((currentLodge.calendar[i].price < minNightlySlotCurrentPrice) && (currentLodge.calendar[i].is_block == 0) && moment(currentLodge.calendar[i].start).isAfter(now)) {
                    minNightlySlotCurrentPrice = currentLodge.calendar[i].price;
                  }
                }
                vm.consumerPrice = minNightlySlotCurrentPrice;
              }
              if ((vm.adventureDetails.single_day_activity != CONST_HELPER.ONE)) {
                $scope.destroyDateRangePicker(); // destroying datepicker
                $scope.initializeKendoDatePicker(); // destroying datepicker
              } else {
                $scope.destroySingleDateRangePicker(); // destroying datepicker
                $scope.initializeKendoSingleDatePicker(); // destroying datepicker
              }
            }
            vm.currentLodging = vm.adventureDetails.lodgings[nextSlide - CONST_HELPER.ONE];
            // if(nextSlide==CONST_HELPER.ZERO && packageSlot.length || vm.adventureDetails.package_slots==CONST_HELPER.ZERO)
            vm.adventureDetails.package_slots = copiedSlots;
            if (vm.booking_type == CONST_HELPER.BOOKING_TYPE.PACKAGE_SLOTS) {
              if (nextSlide === CONST_HELPER.ZERO) {
                if (vm.adventureDetails.package_case == CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) {
                  vm.setMaxGuestCapacityForSlots();
                }
              } else {
                vm.setMaxGuestCapacityForSlots();
              }
              $scope.adultRange = vm.getMaxAvailablePackageSlots();
            }
            if (!vm.currentLodging) {
              return;
            } // if no current lodging selected

            packageSlot = angular.copy(vm.adventureDetails.package_slots);
            var filteredLodgingSlot = packageSlot.filter(function (slot) {
              return slot.slot_lodgings.map(function (l) {
                if (l.available_units > CONST_HELPER.ZERO) {
                  return l.lodging_id;
                }
              }).indexOf(vm.currentLodging.id) !== -CONST_HELPER.ONE;
            });
            if (Array.isArray(filteredLodgingSlot)) {
              vm.adventureDetails.package_slots = filteredLodgingSlot;
            }

            if (vm.adventureDetails.package_case === CONST_HELPER.TWO) {
              setCurrentSlotLodging();
            }
          },
          afterChange: function (_event, _slick, _currentSlide, nextSlide) {
            _slick.$nextArrow[0].style.pointerEvents = "auto";
            _slick.$prevArrow[0].style.pointerEvents = "auto";
          }
        }
      };
    }, 500);
  }

  // view all slots
  vm.viewAllSlots = function (adventure) {
    vm.adventure = adventure;
  };
  vm.trustAsHtml = function (string) {
    return $sce.trustAsHtml(string);
  };

  var previousSlot;
  vm.selectedSlots = {};
  vm.selectSlots = function (model, slot) {
    if (previousSlot === model) {
      if (vm.adventure) {
        vm.adventure.selected_slot = false;
      } else {
        vm.adventureDetails.selected_slot = false;
      }
      vm.selectedSlots = {};
      previousSlot = '';
    } else {
      previousSlot = model;
      vm.selectedSlots = slot;
    }
  };
  // function to recenter package location on map...
  vm.locationInfoClicked = function (lat, lng) {
    NgMap.getMap().then(function (map) {
      var latlng = new google.maps.LatLng(lat, lng);
      map.setCenter(latlng);
      var zoom = map.getZoom(); // get the current zoom...
      zoom = zoom >= CONST_HELPER.DEFAULT_ZOOM ? zoom : CONST_HELPER.DEFAULT_ZOOM;
      map.setZoom(zoom);
    });
  };

  // add to user radar list...

  vm.addToRadar = function () {
    if (vm.isUserLoggedIn) { // user logged in
      addToUserRadarList(adventurePackage);
    } else { // user not logged in
      $cookies.put('addToRadarPackageId', adventurePackage.package_id);
      $state.go('signin');
    }
  };

  function addToUserRadarList (Package) {
    $scope.showLoader();
    // Api request to add packages to user radar list
    userAdventureDetailService.addToUserRadar(Package).then(function (res) {
      if (res.data && res.data.code === CONST_HELPER.API_RESPONSE.OK) {
        vm.adventureDetails.is_radar = CONST_HELPER.ONE;
      }
      $scope.hideLoader();
    }, function (_error) {
      $scope.hideLoader();
      $scope.showPopup('#serverErrModal');
    });
    $cookies.put('addToRadarPackageId', null);
  }

  // remove from radar
  vm.removeFromRadar = function () {
    $scope.showLoader();
    // Api request to remove packages to user radar list
    userAdventureDetailService.removeFromUserRadar(adventurePackage).then(function (_res) {
      vm.adventureDetails.is_radar = CONST_HELPER.ZERO;
      $scope.hideLoader();
    }, function (_error) {
      $scope.hideLoader();
      $scope.showPopup('#serverErrModal');
    });
  };

  // function to proceed the bookings...
  vm.proceed = function (slotId) {
    var slotPopup = document.getElementById('slots');
    if (slotPopup.offsetHeight > CONST_HELPER.ZERO && slotPopup.offsetWidth > CONST_HELPER.ZERO) {
      $scope.hidePopup('#slots');
    }
    $state.go('package-slot-addons-summary', {
      'adults': vm.adult,
      'selected_slot_id': slotId,
      'lodging_id': vm.currentLodging ? vm.currentLodging.id : '',
      'referral_id': $stateParams.referral_id
    });
  };

  // event handler for nightly data
  vm.proceedWithNightly = function () {
    vm.nightly_selected_dates.start = vm.nightly_selected_dates.start.getTime();
    vm.nightly_selected_dates.end = vm.nightly_selected_dates.end.getTime();
    $state.go('package-slot-addons-summary', {
      'adults': vm.adult,
      'package_id': vm.adventureDetails.id,
      'lodging_id': vm.currentLodging ? vm.currentLodging.id : '',
      'start_date': vm.nightly_selected_dates.start ? vm.nightly_selected_dates.start : '',
      'end_date': vm.nightly_selected_dates.end ? vm.nightly_selected_dates.end : '',
      'referral_id': $stateParams.referral_id
    });
  };
  vm.getMaxAvailablePackageSlots = function () {
    if (vm.adventureDetails.package_case == CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) {
      return Math.max.apply(Math, vm.adventureDetails.package_slots.map(function (o) {
        if (!o.expired && moment(o.from_date).isAfter(moment(new Date()))) {
          return o.calculatedMaxGuests;
        } else {
          return CONST_HELPER.ZERO;
        }
      }));
    } else {
      return Math.max.apply(Math, vm.adventureDetails.package_slots.map(function (o) {
        if (!o.expired && moment(o.from_date).isAfter(moment(new Date()))) {
          return o.available_seat;
        } else {
          return CONST_HELPER.ZERO;
        }
      }));
    }
  };
  vm.setMaxGuestCapacityForSlots = function () {
    vm.adventureDetails.package_slots.map(function (val) {
      if (val.slot_lodgings && val.slot_lodgings.length > CONST_HELPER.ZERO) {
        var max = val.slot_lodgings[CONST_HELPER.ZERO].available_units * isNaN(val.slot_lodgings[CONST_HELPER.ZERO].max_guests) ? 0 : val.slot_lodgings[CONST_HELPER.ZERO].max_guests;
        var totalcapacity = CONST_HELPER.ZERO;
        val.slot_lodgings.forEach(function (lodging) {
          if (vm.currentLodging && vm.currentLodging.id == lodging.lodging_id) {
            var capacity = lodging.available_units * lodging.max_guests;
            max = max < capacity ? capacity : max;
          } else {
            totalcapacity += (lodging.available_units * lodging.max_guests);
            max = max < totalcapacity ? totalcapacity : max;
          }
        });
        val.calculatedMaxGuests = max;
      }
    });
  };

  // function to like the package...
  vm.likePackage = function (isLike) {
    $rootScope.isUserLoggedIn(); // check if user is logged in or not
    if (!isLike) { // user has like the package
      var data = {
        'package_id': $stateParams.package_id,
        'referral_id': $stateParams.referral_id,
        'token': $scope.user.token
      };
      userAdventureDetailService.likePackage(data).then(function (res) {
        switch (res.data.code) {
          case CONST_HELPER.API_RESPONSE.OK:
            vm.adventureDetails.package_is_likes = true;
            vm.adventureDetails.package_likes_count = res.data.data.package_likes_count;
            vm.adventureDetails.feed_id = res.data.data.feed_id; // feed_id will be use for unliking the package
            break;
          default:
            $scope.showPopup('#serverErrModal');
            break;
        }
      }, function (_error) {
        $scope.hideLoader();
        $scope.showPopup('#serverErrModal');
      });
    } else { // user has unlike the package
      // eslint-disable-next-line no-redeclare
      var data = {
        feed: {
          feed_id: vm.adventureDetails.feed_id
        },
        token: $scope.user.token
      };

      publicFeedsService.unlikeFeed(data).then(function (res) {
        switch (res.data.code) {
          case CONST_HELPER.API_RESPONSE.OK:
            vm.adventureDetails.package_is_likes = false; // user unlike the package
            vm.adventureDetails.package_likes_count = res.data.numFound;
            break;
          case CONST_HELPER.API_RESPONSE.NOT_FOUND:
            $scope.showPopup('#serverErrModal');
            // $scope.showPopup("#feedNotExist");  // when feed no longer exists.
            break;
          default:
            $scope.showPopup('#serverErrModal');
            break;
        }
      }, function (_error) {
        $scope.hideLoader();
        $scope.showPopup('#serverErrModal');
      });
    }
  };
  $scope.itinerary_limit = CONST_HELPER.SEVEN;
  vm.showLess = false;
  $scope.loadMoreItinerary = function () {
    vm.showLess = !vm.showLess;
    if (vm.showLess) {
      $scope.itinerary_limit = vm.adventureDetails.package_day_details.length;
    } else {
      $scope.itinerary_limit = CONST_HELPER.SEVEN;
    }
  };
  $scope.openGallery = function (lodging) {
    if (lodging) {
      vm.galleryImages = lodging.lodging_medias;
      vm.galleryTitle = lodging.name;
    } else {
      vm.galleryImages = vm.package_location_images;
      vm.galleryTitle = vm.adventureDetails.name;
    }
    $timeout(function () {
      $scope.showPopup('#gallary');
    });
    vm.galleryOpened = true;
  };

  $scope.showMore = function (limit, lodging) {
    lodging.textLimit = limit;
  };
  $scope.slickVideoHeliConfig = {
    enabled: true,
    autoplay: false,
    infinite: false,
    draggable: false,
    arrows: true,
    slidesToShow: 1,
    event: {
      beforeChange: function (event, slick, currentSlide, nextSlide) {
        var current = $(slick.$slides[currentSlide]);
        current.html(current.html());
      }
    }
  };

  // lodging changes goes here ...
  var packageSlot;
  $scope.slickHeliLodgingConfig = {
    enabled: true,
    autoplay: false,
    draggable: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    event: {
      beforeChange: function (_event, _slick, _currentSlide, nextSlide) {
        // code for handling nightly slots on slide change
        if (vm.booking_type == CONST_HELPER.BOOKING_TYPE.NIGHTLY_SLOTS && $scope.checkForEmptyObjectAndArray(vm.adventureDetails.nightly_slots)) {
          if (nextSlide === CONST_HELPER.ZERO) {
            vm.calendar = $scope.getCalendar(vm.adventureDetails.nightly_slots.nightly_slot_lodgings);
            vm.consumerPrice = minNightlySlot;
          } else {
            vm.calendar = $scope.getCalendar([vm.adventureDetails.nightly_slots.nightly_slot_lodgings[nextSlide - CONST_HELPER.ONE]]);
            var currentLodge = vm.adventureDetails.nightly_slots.nightly_slot_lodgings[nextSlide - CONST_HELPER.ONE];
            var minNightlySlotCurrentPrice = currentLodge.price;
            for (var i = 0; i < currentLodge.calendar.length; ++i) {
              if ((currentLodge.calendar[i].price < minNightlySlotCurrentPrice) && (currentLodge.calendar[i].is_block == 0) && moment(currentLodge.calendar[i].start).isAfter(now)) {
                minNightlySlotCurrentPrice = currentLodge.calendar[i].price;
              }
            }
            vm.consumerPrice = minNightlySlotCurrentPrice;
          }
          if ((vm.adventureDetails.single_day_activity != CONST_HELPER.ONE)) {
            $scope.destroyDateRangePicker(); // destroying datepicker
            $scope.initializeKendoDatePicker(); // destroying datepicker
          } else {
            $scope.destroySingleDateRangePicker(); // destroying datepicker
            $scope.initializeKendoSingleDatePicker(); // destroying datepicker
          }
        }
        vm.currentLodging = vm.adventureDetails.lodgings[nextSlide - CONST_HELPER.ONE];
        // if(nextSlide==CONST_HELPER.ZERO && packageSlot.length || vm.adventureDetails.package_slots==CONST_HELPER.ZERO)
        vm.adventureDetails.package_slots = copiedSlots;
        if (vm.booking_type == CONST_HELPER.BOOKING_TYPE.PACKAGE_SLOTS) {
          if (nextSlide === CONST_HELPER.ZERO) {
            if (vm.adventureDetails.package_case == CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) {
              vm.setMaxGuestCapacityForSlots();
            }
          } else {
            vm.setMaxGuestCapacityForSlots();
          }
          $scope.adultRange = vm.getMaxAvailablePackageSlots();
        }
        if (!vm.currentLodging) {
          return;
        } // if no current lodging selected

        packageSlot = angular.copy(vm.adventureDetails.package_slots);
        var filteredLodgingSlot = packageSlot.filter(function (slot) {
          return slot.slot_lodgings.map(function (l) {
            if (l.available_units > CONST_HELPER.ZERO) {
              return l.lodging_id;
            }
          }).indexOf(vm.currentLodging.id) !== -CONST_HELPER.ONE;
        });
        if (Array.isArray(filteredLodgingSlot)) {
          vm.adventureDetails.package_slots = filteredLodgingSlot;
        }

        if (vm.adventureDetails.package_case === CONST_HELPER.TWO) {
          setCurrentSlotLodging();
        }
      }
    }
  };
  // set current lodging for case B
  function setCurrentSlotLodging () {
    vm.adventureDetails.package_slots.map(function (slot) {
      var currentSlotLodging = slot.slot_lodgings.find(function (sl) {
        return sl.lodging_id === vm.currentLodging.id;
      });
      slot.currentSlotLodging = currentSlotLodging;
    });
  }

  $scope.getLodgingPrice = function (slot) {
    var lodgingPrice = CONST_HELPER.ZERO;
    if (vm.currentLodging && slot.slot_lodgings && slot.slot_lodgings.length) {
      var selectedSlotLoding = slot.slot_lodgings.filter(function (sl) {
        return sl.lodging_id === vm.currentLodging.id;
      });
      lodgingPrice = selectedSlotLoding.length ? selectedSlotLoding[CONST_HELPER.ZERO].price : slot.min_price;
    } else {
      lodgingPrice = slot.slot_lodgings.length ? slot.min_price : slot.adult_price;
    }
    return lodgingPrice;
  };
  $scope.callApiForOperatorProfile = function () {
    var hostName = $location.host();
    if ((hostName.match(new RegExp('\\.', 'g')) || []).length > 1) {
      $scope.getOperatorPackageListSubDomain(CONST_HELPER.ONE);
    }
  };

  $scope.initializeOperatorPackageShare = function (packageData) {
    $scope.contentOperatorPackageShare = { // initialize the content which is to be share on different sharing options
      description: packageData.description[CONST_HELPER.ZERO],
      short_url: (packageData.short_url) ? packageData.short_url : '',
      profile_pic: ($scope.user.profile_pic) ? $scope.user.profile_pic : '',
      package_name: (packageData.name) ? packageData.name : '',
      package_id: packageData.package_id,
      location_name: packageData.location_name[CONST_HELPER.ZERO],
      duration: packageData.duration[CONST_HELPER.ZERO],
      id: packageData.package_id,
      media: packageData.package_medias[CONST_HELPER.ZERO],
      min_price: packageData.min_price,
      currency_code: packageData.currency.code,
      slot: {},
      package_type: packageData.package_type,
      status: packageData.status ? packageData.status : ''
    };
  };

  $scope.getOperatorPackageListSubDomain = function (page) { //  get active package list function
    $scope.showLoader();
    $scope.loading = true;
    $scope.page = page;
    var hostName = $location.host();
    var requestObject = {
      'user_name': hostName.slice(0, hostName.indexOf('.')),
      'domain_url': $location.absUrl(),
      'page': $scope.page,
      'limit': CONST_HELPER.LIST.SIZE,
      'back': CONST_HELPER.ZERO
    };
    userSocialProfileService.operatorPackageListSubDomain(requestObject).then(function (response) {
      if (response && response.data && response.data.response) {
        $scope.loading = false;
        $rootScope.pageDetails = response.data.response.pageDetails[0];
        $scope.pageDetails = response.data.response.pageDetails[0];

        if ($rootScope.pageDetails && !$rootScope.pageDetails.is_verified) {
          // Show Black page if not verified domain.
          $scope.packageDataNotFound = true;
          $rootScope.disableHeaderBackBtn = true;
          rootScopeService.setValue("disableHeaderBackBtn", $rootScope.disableHeaderBackBtn);
        }

        if (($rootScope.pageDetails.head_font || $rootScope.pageDetails.body_font) && WebFont) {
          WebFont.load({
            google: {
              families: [$rootScope.pageDetails.head_font, $rootScope.pageDetails.body_font]
            }
          });

          var customStyles = '* {font-family: ' + ($rootScope.pageDetails.body_font ? ($rootScope.pageDetails.body_font.toString() +' !important') : '') + '}'
          + '.domain-heading { font-family: ' + ($rootScope.pageDetails.head_font ? ($rootScope.pageDetails.head_font.toString() +' !important') : '') + '}'
          + '.domain-btn { background-color: ' + ($rootScope.pageDetails.button_color ? ($rootScope.pageDetails.button_color.toString() +' !important;') : '') + 'color: ' + ($rootScope.pageDetails.button_text_color ? ($rootScope.pageDetails.button_text_color.toString() +' !important') : '') +'}'
          +'.domain-btn:active, .domain-btn:focus, .domain-btn:hover { background-color: ' + ($rootScope.pageDetails.button_color ? ($rootScope.pageDetails.button_color.toString() +' !important;') : '') + 'color: ' + ($rootScope.pageDetails.button_text_color ? ($rootScope.pageDetails.button_text_color.toString() +' !important') : '') +'}';
          $('<style>').text(customStyles).appendTo(document.head);
          // end set custom styles
        }
        $scope.hideLoader();
      } else {
        $scope.packageDataNotFound = true;
        $rootScope.disableHeaderBackBtn = true;
        rootScopeService.setValue("disableHeaderBackBtn", $rootScope.disableHeaderBackBtn);
      }
    }, function () {
      $scope.hideLoader();
      $scope.showPopup('#serverErrModal');
    });
  };

  if ($rootScope.isSubDomain && !$rootScope.isAgent) {
    $scope.callApiForOperatorProfile();
  }
  $rootScope.$on('sub-domain-check', function () {
    if ($rootScope.isSubDomain && !$rootScope.isAgent) {
      $scope.callApiForOperatorProfile();
    }
  });
  $scope.$watch('$root.currencyAlreadyFounded', function (currencyNew, currencyOld) {
    if(vm.adventureDetails && vm.adventureDetails.currency){
      $scope.getSlotUsdRate(vm.adventureDetails.currency.code, $rootScope.localCurrencyCode, function (currencyRate) {
        $scope.localCurencyRate = currencyRate;
      });
    }
  });

  $scope.isAdventureExpired = (date) => {
    return moment(date) < moment(new Date());
  }
}
