import { Component, Inject, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { UIRouter } from '@uirouter/core';
import * as angular from 'angular';
import { RootScopeService } from '../../services/root-scope/root-scope.service';

@Component({
  selector: 'footer-page',
  templateUrl: '/ng-app/src/app/components/footer/footer.component.html',
  styleUrls: ['../../../../../ng-app/src/app/components/footer/footer.component.css']
})
export class FooterComponent implements OnInit {

  copyrightYear: Date = new Date();

  constructor(
    @Inject(UIRouter) public router: UIRouter,
    @Inject(RootScopeService) public rootScopeService: RootScopeService
  ) { }

  ngOnInit(): void {
  }

}

angular.module('heliApp').directive("footerPage", downgradeComponent({ component: FooterComponent }))