import * as angular from 'angular';
import { HomeComponent } from '../../ng-app/src/app/pages/home/home.component';
import { OperatorsListingComponent } from '../../ng-app/src/app/pages/operators-listing/operators-listing.component';
/* eslint-disable handle-callback-err */
/* eslint-disable no-undef */
'use strict';
/**
 * @ngdoc overview
 * @name heliApp
 * @description
 * # heliApp
 *
 * Main module of the application.
 */
angular.module('heliApp').config(['$stateProvider', '$urlRouterProvider', '$locationProvider', function ($stateProvider, $urlRouterProvider, $locationProvider) {
  $urlRouterProvider.otherwise('/');
  $locationProvider.html5Mode({
    enabled: true
  });
  $stateProvider
    // //Angular new Components
    // .state('test-page', {
    //   url: '/test-page',
    //   component: TestComponent,
    // })
    // package creation ========================================
    .state('operatorCreateAdventure', {
      url: '/createAdventure?package_id&package_type&package_case&booking_type&edit_slot',
      templateUrl: './views/operator-create-adventure.html',
      controller: 'CreateAdventureCtrl',
      data: {
        meta: {
          'title': 'Create Adventure',
          'description': ''
        }
      },
      reloadOnSearch: false
    })

    .state('operatorCaseSelection', {
      url: '/operatorCaseSelection?package_type',
      templateUrl: './views/operator-case-selection.html',
      controller: 'operatorCaseSelectionCtrl',
      data: {
        meta: {
          'title': 'Operator Case Selection',
          'description': ''
        }
      },
      reloadOnSearch: false
    })

    .state('operatorBookingTypeSelection', {
      url: '/operatorBookingTypeSelection?package_type&package_case',
      templateUrl: './views/operator-booking-type-selection.html',
      controller: 'operatorBookingTypeSelectionCtrl',
      data: {
        meta: {
          'title': 'Operator Booking Type Selection',
          'description': ''
        }
      },
      reloadOnSearch: false
    })

    // package creation ========================================
    .state('redirect', {
      url: '/r/{redirectCode}',
      component: HomeComponent,
      data: {
        pageTitle: 'Home'
      },
      // controller: 'SearchCtrl'
    })
    // home page for search ========================================
    .state('home', {
      url: '/',
      component: HomeComponent,
      resolve: {
        showPage: ['$rootScope', '$location', 'userSocialProfileService', '$state', 'CONST_HELPER', 'CURRENT_HOST','rootScopeService', function ($rootScope, $location, userSocialProfileService, $state, CONST_HELPER, CURRENT_HOST, rootScopeService) {
          var hostName = $location.host();
          if (!CURRENT_HOST.includes(hostName) && !hostName.startsWith('localhost')) {
            var username = hostName.split('.')[0];
            console.log('tag', username, username == 'agents');
            if (username === 'agents') {
              $rootScope.isAgent = true;
              rootScopeService.setValue("isAgent",$rootScope.isAgent);
              // $state.go('new-route-agent');
            } else {
              var isNotExternalDomain = false;
              CURRENT_HOST.forEach(function (host) {
                isNotExternalDomain = hostName.indexOf(host) > -1;
              });
              var requestObject = {};
              if (isNotExternalDomain) {
                requestObject = {
                  'username': username
                };
              } else {
                requestObject = {
                  'username': username,
                  'sub_domain_url': $location.protocol() + '://' + hostName
                };
              }
              userSocialProfileService.getUserSocialProfile(requestObject, '').then(function (response) {
                if (response.data.code === CONST_HELPER.API_RESPONSE.OK) {
                  var userProfile = response.data.data;
                  if (userProfile.role_id == 3) {
                    $rootScope.isSubDomain = true;
                    $rootScope.siteInIFrame = true;
                    rootScopeService.setValue("siteInFrame", $rootScope.siteInIFrame);
                    $state.go('new-route-white-label');
                  } else {
                    // ----Agent-------
                    $rootScope.isAgent = true;
                    rootScopeService.setValue("isAgent",$rootScope.isAgent);
                    $rootScope.isSubDomain = false;
                    $rootScope.siteInIFrame = false;
                    rootScopeService.setValue("siteInFrame", $rootScope.siteInIFrame);
                    if ($rootScope.isUserLoggedIn) {
                      var hostName = $location.host();
                      if (hostName != 'localhost' && username != userProfile.username) {
                        console.log('tag', hostName);
                        var protocol = window.location.href.split('://')[0];
                        hostName = window.location.host.substr(window.location.host.indexOf('.'));
                        console.log('hostname', protocol + '://' + userProfile.username + hostName + '/agent-calendar');
                        window.location.href = protocol + '://' + userProfile.username + hostName + '/agent-calendar';
                      } else {
                        $state.go('signin');
                      }
                    } else {
                      $state.go('signin');
                    }
                  }
                } else if (response.data.code === CONST_HELPER.API_RESPONSE.UNPROCESSABLE) {

                } else if (response.data.code === CONST_HELPER.API_RESPONSE.BAD_REQUEST) {
                  $state.go('home');
                }
              }, function () {
              });
            }
          }
        }
        ]
      },
      data: {
        meta: {
          'title': 'Heli Adventures | Unite Through Adventure',
          'description': "Discover experiences from the world's best providers on Heli, the global marketplace for adventure travel.",
          'image': 'https://s3.amazonaws.com/heli-staging/homepage-images/heli-video-cover.png'
        }
      }
      // controller:'SearchCtrl',
    })
    .state('new-route-white-label', {
      url: '',
      component: HomeComponent,
      data: {
        meta: {
          'title': 'My Profile',
          'description': 'Find your heli social profile.'
        }
      }
    })
    .state('new-route-agent', {
      url: '',
      data: {
        meta: {
          'title': 'Agent',
          'description': 'Become an agent'
        }
      }
    })
    // Activity search page ========================================
    .state('activity-search', {
      url: '/category/{activity_name}',
      templateUrl: './views/activity-filter.html',
      data: {
        meta: {
          'title': 'Explore All Adventures On Heli',
          'description': 'See our full list of adventures from operators around the globe. Shop and book directly online today.'
        }
      },
      controller: 'ActivityfilterCtrl'
    })
    // adventure Detail page ========================================
    .state('adventure-detail', {
      url: '/adventure-detail/{operatorname}/{packagename}/{package_id}/:package_status?/:slot_id?/:referral_id?',
      templateUrl: './views/user-package-detail.html',
      controller: 'userAdventureDetailCtrl',
      controllerAs: 'vm',
      params: {package_status: null, slot_id: null, referral_id: null },
      data: {
        meta: {
          'title': 'Package Detail',
          'description': '',
          'image': '',
          'url': ''
        }
      }
    })
    .state('adventure-detail-private', {
      url: '/adventure-detail/private/{operatorname}/{packagename}/{package_id}/:package_status?/:slot_id?/:referral_id?',
      templateUrl: './views/user-package-detail.html',
      controller: 'userAdventureDetailCtrl',
      controllerAs: 'vm',
      params: {package_status: null, slot_id: null, referral_id: null },
      data: {
        meta: {
          'title': 'Package Detail',
          'description': ''
        }
      }
    })
    // handle previous adventure Detail page ========================================
    .state('adventure-detail-pre', {
      url: '/adventure-detail?package_id&referral_id',
      templateUrl: './views/user-package-detail.html',
      controller: 'userAdventureDetailCtrl',
      controllerAs: 'vm',
      data: {
        meta: {
          'title': 'Package Detail',
          'description': ''
        }
      }
    })
    // signin page ========================================
    .state('signin', {
      url: '/signin',
      templateUrl: './views/user-signin.html',
      controller: 'UserLoginCtrl',
      data: {
        meta: {
          'title': 'Sign In To Heli',
          'description': 'Sign in to your Heli account',
          'image': 'https://heli.life/app/images/sign-inup-1.jpg'
        }
      }
    }) // signin page ========================================
    .state('signup', {
      url: '/signup?referral_id&signup_token',
      templateUrl: './views/user-signup.html',
      controller: 'UserRegisterationCtrl',
      data: {
        meta: {
          'title': 'Sign Up For Heli',
          'description': 'Sign up for Heli and start exploring today.',
          'image': 'https://heli.life/app/images/sign-inup-1.jpg'
        }
      }
    })
    .state('forgotPassword', {
      url: '/forgotPassword',
      templateUrl: './views/user-forgot-password.html',
      controller: 'UserLoginCtrl',
      data: {
        meta: {
          'title': 'Forgot Password',
          'description': ''
        }
      }
    })
    // operator package Detail page ========================================
    .state('operator-package-detail', {
      url: '/operator-package-detail?package_id',
      templateUrl: './views/operator-package-detail.html',
      controller: 'operatorPackageDetailCtrl',
      controllerAs: 'vm',
      data: {
        meta: {
          'title': 'Package Detail',
          'description': ''
        }
      }
    })
    .state('operator-signup', {
      url: '/operator-signup',
      templateUrl: './views/operator-signup.html',
      data: {
        meta: {
          'title': 'Sell Your Adventures on Heli',
          'description': 'Apply to be an operator on Heli and offer your adventures to our global community of explorers and athletes.',
          'image': 'https://heli.life/app/images/operator-video-cover.png'
        }
      },
      controller: 'operatorSignupCtrl'
    })
    .state('operator-dashboard', {
      url: '/operator-dashboard',
      templateUrl: './views/operator-dashboard.html',
      data: {
        meta: {
          'title': 'Dashboard',
          'description': ''
        }
      },
      controller: 'OperatorPackageListCtrl'
    })
    .state('agent-adventures', {
      url: '/agent-adventures?operator_id',
      templateUrl: './views/operator-dashboard.html',
      data: {
        meta: {
          'title': 'Adventures',
          'description': ''
        }
      },
      controller: 'OperatorPackageListCtrl'
    })
    .state('operator-add-location', {
      url: '/operator-add-location',
      templateUrl: './views/operator-add-location.html',
      data: {
        meta: {
          'title': 'Dashboard',
          'description': ''
        }
      },
      controller: 'operatorProfileCtrl'
    })
    .state('operator-view-booking', {
      url: '/operator-view-booking?package_id&page&slot_id',
      templateUrl: './views/operator-view-booking.html',
      data: {
        meta: {
          'title': 'View Booking',
          'description': ''
        }
      },
      controller: 'operatorViewBookingCtrl'
    })
    .state('operator-booking-detail', {
      url: '/operator-booking-detail?booking_slot_id',
      templateUrl: './views/operator-booking-detail.html',
      data: {
        meta: {
          'title': 'Booking Detail',
          'description': ''
        }
      },
      controller: 'operatorViewBookingCtrl'
    })
    .state('operator-booking-list', {
      url: '/operator-booking-list?page&booking_source&location_id&to_date&from_date&sort_value&reverse&packageIds&booking_status&filter_date_type',
      templateUrl: './views/operator-booking-list.html',
      data: {
        meta: {
          'title': 'Booking List',
          'description': ''
        }
      },
      controller: 'operatorViewBookingCtrl'
    })
    .state('operator-agent-booking-list', {
      url: '/operator-agent-booking-list?page&booking_source&location_id&to_date&from_date&sort_value&reverse&packageIds&booking_status',
      templateUrl: './views/operator-agent-booking-list.html',
      data: {
        meta: {
          'title': 'Booking List',
          'description': ''
        }
      },
      controller: 'operatorViewBookingCtrl'
    })
    .state('agent-booking-list', {
      url: '/agent-booking-list?page&booking_source&location_id&to_date&from_date&sort_value&reverse&packageIds&booking_status&op_id',
      templateUrl: './views/operator-booking-list.html',
      data: {
        meta: {
          'title': 'Booking List',
          'description': ''
        }
      },
      controller: 'operatorViewBookingCtrl'
    })
    .state('operator-success-msg', {
      url: '/operator-success-msg',
      templateUrl: './views/operator-success-msg.html',
      data: {
        meta: {
          'title': 'Success',
          'description': ''
        }
      },
      controller: 'operatorSignupCtrl'
    })
    .state('operator-signin', {
      url: '/operator-signin?signup_token',
      templateUrl: './views/operator-signin.html',
      data: {
        meta: {
          'title': 'Operator Sign In',
          'description': ''
        }
      },
      controller: 'operatorSignInCtrl'
    })
    .state('link-expire', {
      url: '/link-expire',
      templateUrl: './views/link-expire.html',
      data: {
        meta: {
          'title': 'Link Expire',
          'description': ''
        }
      }
    })
    .state('resetPassword', {
      url: '/resetPassword?token',
      templateUrl: './views/user-signup.html',
      controller: 'UserRegisterationCtrl',
      data: {
        meta: {
          'title': 'Reset Password',
          'description': ''
        }
      }
    })
    .state('forgot-success', {
      url: '/success',
      templateUrl: './views/forgot-success-msg.html',
      data: {
        meta: {
          'title': 'Success',
          'description': ''
        }
      }
    })
    .state('emailVerification', {
      url: '/emailVerification?token',
      templateUrl: './views/email-verification-success.html',
      controller: 'EmailVerificationCtrl',
      data: {
        meta: {
          'title': 'Email Verification',
          'description': ''
        }
      }
    })
    .state('operator-get-started', {
      url: '/operator-get-started',
      templateUrl: './views/operator-get-started.html',
      controller: 'operatorGetStartedCtrl',
      data: {
        meta: {
          'title': 'Get Started',
          'description': ''
        }
      }
    })
    .state('consumer-profile', {
      url: '/user-profile',
      templateUrl: './views/user-profile.html',
      controller: 'userProfileCtrl',
      data: {
        meta: {
          'title': 'Profile',
          'description': ''
        }
      }
    })
    .state('operator-basic-profile', {
      url: '/operator-basic-profile',
      templateUrl: './views/operator-basic-profile.html',
      controller: 'operatorProfileCtrl',
      data: {
        meta: {
          'title': 'Basic Profile',
          'description': ''
        }
      }
    })
    .state('operator-edit-profile', {
      url: '/operator-edit-profile?stripe_status',
      templateUrl: './views/operator-edit-profile.html',
      controller: 'operatorProfileCtrl',
      data: {
        meta: {
          'title': 'Edit Profile',
          'description': ''
        }
      }
    })
    .state('packgeDetail', {
      url: '/packgeDetail',
      templateUrl: './views/package-detail.html',
      controller: 'packgeDetail',
      data: {
        meta: {
          'title': 'Package Detail',
          'description': ''
        }
      }
    })
    .state('consumer-radar', {
      url: '/radar?page&back',
      templateUrl: './views/user-radar.html',
      controller: 'userRadarCtrl',
      controllerAs: 'vm',
      data: {
        meta: {
          'title': 'Radar',
          'description': ''
        }
      }
    })
    .state('package-slot-addons-summary', {
      url: '/package-slot-addons-summary?selected_slot_id&cart_id&package_id&lodging_id&start_date&end_date&adults&referral_id&selectedRoomOptions&selectedAddons',
      templateUrl: './views/user-package-detail-summary.html',
      controller: 'userPackageSlotAddons',
      controllerAs: 'vm',
      data: {
        meta: {
          'title': 'Package Slot Detail',
          'description': ''
        }
      },
      reloadOnSearch: false,
    })
    .state('user-orders', {
      url: '/orders-list?order_id',
      templateUrl: './views/user-order-list.html',
      controller: 'userOrderListCtrl',
      data: {
        meta: {
          'title': 'Order List',
          'description': ''
        }
      }
    })
    .state('mycart', {
      url: '/mycart',
      templateUrl: './views/user-cart.html',
      controller: 'myCartCtrl',
      controllerAs: 'vm',
      data: {
        meta: {
          'title': 'My Cart',
          'description': ''
        }
      }
    })
    .state('guest-details', {
      url: '/guest-details',
      templateUrl: './views/guest-details.html',
      controller: 'myCartCtrl',
      controllerAs: 'vm',
      data: {
        meta: {
          'title': 'Guest Detail',
          'description': ''
        }
      }
    })
    .state('guest-details-summary', {
      url: '/guest-details-summary?adults',
      templateUrl: './views/guest-details.html',
      controller: 'userPackageSlotAddons',
      controllerAs: 'vm',
      data: {
        meta: {
          'title': 'Guest Detail',
          'description': ''
        }
      }
    })
    .state('secure-checkout', {
      url: '/secure-checkout?order_id&iframe&url',
      templateUrl: './views/user-shipping-address.html',
      controller: 'secureCheckoutCtrl',
      controllerAs: 'vm',
      data: {
        meta: {
          'title': 'Secure Checkout',
          'description': ''
        }
      }
    })
    .state('order-successful', {
      url: '/order-successful',
      templateUrl: './views/user-order-successful.html',
      controller: 'userOrderSuccessCtrl',
      controllerAs: 'vm',
      data: {
        meta: {
          'title': 'Order Successful',
          'description': ''
        }
      }
    })
    .state('booking-detail', {
      url: '/booking-detail',
      templateUrl: './views/booking-detail.html',
      controller: 'booking-detail',
      data: {
        meta: {
          'title': 'Booking Detail',
          'description': ''
        }
      }
    })
    .state('view-booking', {
      url: '/view-booking',
      templateUrl: './views/view-booking.html',
      controller: 'view-booking',
      data: {
        meta: {
          'title': 'Booking',
          'description': ''
        }
      }
    })
    .state('booking-list', {
      url: '/booking-list',
      templateUrl: './views/booking-list.html',
      controller: 'booking-list',
      data: {
        meta: {
          'title': 'Booking List',
          'description': ''
        }
      }
    })
    .state('ambassadors-listing', {
      url: '/ambassadors-listing?back&page&search&sort',
      templateUrl: './views/ambassadors-listing.html',
      controller: 'ambassadorsListingCtrl',
      data: {
        meta: {
          'title': 'Heli Ambassadorss',
          'description': 'See all our ambassadors, athletes, and group leaders who are inspiring others to get outdoors.',
          'image': 'https://heli.life/images/new-feeds-ambassdors.jpg'
        }
      },
      reloadOnSearch: false
    })
    .state('operators-listing', {
      url: '/operators-listing?page&search&back',
      // templateUrl: './views/operators-listing.html',
      component: OperatorsListingComponent,
      // controller: 'operatorListingCtrl',
      data: {
        meta: {
          'title': 'Heli List of Operators',
          'description': 'See a full list of partners and operations who offer their adventures on Heli.',
          'image': 'https://heli.life/images/new-feeds-operators.jpg'
        }
      },
      reloadOnSearch: false,
      params: {page: '1'}
    })
    .state('privacy-policy', {
      url: '/privacy-policy',
      templateUrl: './views/privacy-policy.html',
      controller: '',
      data: {
        meta: {
          'title': 'Privacy Policy',
          'description': 'Privacy policy for the use of the Heli platform.',
          'image': 'https://heli.life/app/images/privacy-policy-banner.jpg'
        }
      }
    })
    .state('terms-conditions', {
      url: '/terms-conditions',
      templateUrl: './views/terms-condition.html',
      controller: '',
      data: {
        meta: {
          'title': 'Terms and Conditions',
          'description': 'Terms and conditions for the Heli platform.',
          'image': 'https://heli.life/app/images/terms-condition-banner.jpg'
        }
      }
    })
    .state('followers-listing', {
      url: '/followers-listing?page&back&other_userId',
      templateUrl: './views/followers-listing.html',
      controller: 'followerListingCtrl',
      data: {
        meta: {
          'title': 'Followers Listing',
          'description': ''
        }
      },
      reloadOnSearch: false
    })
    .state('following-listing', {
      url: '/following-listing?page&back&other_userId',
      templateUrl: './views/following-listing.html',
      controller: 'followingListingCtrl',
      data: {
        meta: {
          'title': 'Following Listing',
          'description': ''
        }
      },
      reloadOnSearch: false
    })
    .state('user-listing', {
      url: '/user-listing?page&search&back',
      templateUrl: './views/user-listing.html',
      controller: 'userListingCtrl',
      data: {
        meta: {
          'title': 'Find Your Friends on Heli',
          'description': 'Find your friends on Heli to link up and explore the world, together.',
          'image': 'https://heli.life/images/new-feed-friends.jpg'
        }
      },
      reloadOnSearch: false
    })
    .state('mergeAccountSuccess', {
      url: '/mergeAccount?token',
      templateUrl: './views/merge-account-success.html',
      data: {
        meta: {
          'title': 'Merge Account',
          'description': ''
        }
      },
      resolve: {
        showPage: ['$stateParams', 'userRegisterService', '$rootScope', '$state', function ($stateParams, userRegisterService, $rootScope, $state) {
          $rootScope.show = false;
          var emailToken = {
            'merge_account_verification_token': $stateParams.token
          };
          userRegisterService.checkForValidTokenMergeAcc(emailToken).then(function (response) { // post to the server the token for merge account status
            if (response.data) {
              if (response.data.code !== '200') { // some error exits
                $rootScope.show = false;
                $state.go('link-expire'); // move to link expire page
                return false;
              } else {
                $rootScope.show = true;
                return true;
              }
            }
          }, function (error) { // check for error
            return false;
          });
        }]
      }
    })
    .state('booking-cancel-request', {
      url: '/booking-cancel-request',
      templateUrl: './views/booking-cancel-request.html',
      controller: '',
      data: {
        meta: {
          'title': 'Cancel Request',
          'description': ''
        }
      }
    })
    .state('transaction-failed', {
      url: '/transaction-failed',
      templateUrl: './views/transaction-failed.html',
      controller: '',
      data: {
        meta: {
          'title': 'Transaction Failed',
          'description': ''
        }
      }
    })
    .state('user-public-feeds', {
      url: '/feeds/:feedid',
      templateUrl: './views/public-feeds.html',
      controller: 'publicFeedsCtrl',
      params: { feedid: null },
      data: {
        meta: {
          'title': 'Feed',
          'description': ''
        }
      }
    })
    .state('operator-public-feeds', {
      url: '/operator-feeds',
      templateUrl: './views/operator-public-feeds.html',
      controller: 'publicFeedsCtrl',
      data: {
        meta: {
          'title': 'Feed',
          'description': ''
        }
      }
    })
    .state('followRequest', {
      url: '/followRequest?token',
      templateUrl: './views/follow-request-success.html',
      controller: 'followRequestCtrl',
      data: {
        meta: {
          'title': 'Follow Request',
          'description': ''
        }
      }
    })
    .state('operator-edit-slots', {
      url: '/operator-edit-slots?slot_id&nightly_slot_id&start_date&end_date&op_id', // added params for getting nightly slot id
      templateUrl: './views/operator-edit-slots.html',
      controller: 'operatorEditSlotCtrl',
      controllerAs: 'vm',
      data: {
        meta: {
          'title': 'Operator Edit Slots',
          'description': ''
        }
      }
    })
    .state('operator-calendar', {
      url: '/operator-calendar?locations_id&packages_id&calendar_date&select_all&unavailable&empty&occupancy_below74&occupancy_above74&occupancy_full&nightly_booking',
      templateUrl: './views/operator-calendar.html',
      controller: 'operatorCalendarCtrl',
      data: {
        meta: {
          'title': 'Calendar Dashboard',
          'description': ''
        }
      },
      reloadOnSearch: false
    })
    .state('operator-manifest', {
      url: '/manifest?location_id&adventure_id&date_from&date_to&page',
      templateUrl: './views/operator-manifest.html',
      controller: 'operatorManifestCtrl',
      data: {
        meta: {
          'title': 'Manifest'
        }
      },
      reloadOnSearch: false
    })
    .state('agent-calendar', {
      url: '/agent-calendar',
      templateUrl: './views/operator-calendar.html',
      controller: 'operatorCalendarCtrl',
      data: {
        meta: {
          'title': 'Agent Calendar Dashboard',
          'description': ''
        }
      }
    })
    .state('agent-operators', {
      url: '/agent-operators',
      templateUrl: './views/agent-operators.html',
      controller: 'agentOperatorsCtrl',
      data: {
        meta: {
          'title': 'Agent Operator Dashboard',
          'description': ''
        }
      }
    })
    .state('operator-manual-booking', {
      url: '/operator-manual-booking?slot_id&nightly_slot_id&order_id&package_id&start_date&end_date&booking_type&op_id',
      templateUrl: './views/operator-manual-booking.html',
      controller: 'operatorEditSlotCtrl',
      controllerAs: 'vm',
      data: {
        meta: {
          'title': 'Manual Booking',
          'description': ''
        }
      }
    })
    .state('operator-manual-booking-noslot', {
      url: '/operator-manual-booking/',
      templateUrl: './views/operator-manual-booking.html',
      controller: 'operatorEditSlotCtrl',
      controllerAs: 'vm',
      data: {
        meta: {
          'title': 'Manual Booking',
          'description': ''
        }
      }
    })
    .state('manual-payment-list', {
      url: '/manual-payment-list?page&keyword',
      templateUrl: './views/manual-payment-list.html',
      controller: 'operatorManualPaymentCtrl',
      data: {
        meta: {
          'title': 'Manual Payment',
          'description': ''
        }
      },
      reloadOnSearch: false

    })
    .state('guest-form', {
      url: '/guest-form?page&order_by&order_direction&guest_form_title&package_id&date_from&date_to',
      templateUrl: './views/guest-form.html',
      controller: 'operatorGuestFormCtrl',
      data: {
        meta: {
          'title': 'Guest Form'
        }
      },
      reloadOnSearch: false
    })
    .state('add-guest-form', {
      url: '/add-guest-form/:formId',
      templateUrl: './views/add-guest-form.html',
      controller: 'OperatorAddGuestFormCtrl',
      data: {
        meta: {
          'title': 'Add Guest Form'
        }
      },
      params: {formId: null}
    })
    .state('manual-payment', {
      url: '/manual-payment',
      templateUrl: './views/manual-payment.html',
      controller: 'operatorManualPaymentCtrl',
      data: {
        meta: {
          'title': 'Manual Payment',
          'description': ''
        }
      }
    })
    .state('operator-analytics', {
      url: '/operator-analytics',
      templateUrl: './views/operator-analytics.html',
      controller: 'operatorAnalyticsCtrl',
      data: {
        meta: {
          'title': 'Operator Analytics',
          'description': ''
        }
      }
    })
    .state('agent-analytics', {
      url: '/agent-analytics?op_id',
      templateUrl: './views/operator-analytics.html',
      controller: 'operatorAnalyticsCtrl',
      data: {
        meta: {
          'title': 'Agent Analytics',
          'description': ''
        }
      }
    })
    .state('operator-agent-analytics', {
      url: '/operator-agent-analytics',
      templateUrl: './views/operator-agent-analytics.html',
      controller: 'operatorAnalyticsCtrl',
      data: {
        meta: {
          'title': 'Operator Agent Analytics',
          'description': ''
        }
      }
    })
    .state('operator-agent-booking-analytics', {
      url: '/operator-agent-booking-analytics?agent_id',
      templateUrl: './views/operator-agent-booking-analytics.html',
      controller: 'operatorAnalyticsCtrl',
      data: {
        meta: {
          'title': 'Operator Agent Analytics',
          'description': ''
        }
      }
    })
    .state('operator-agent-inventory', {
      url: '/operator-agent-inventory/:id/:agent_id',
      templateUrl: './views/operator-agent-inventory.html',
      controller: 'operatorInventoryCtrl',
      params: { id: null, agent_id: null },
      data: {
        meta: {
          'title': 'Operator Agent Inventory',
          'description': ''
        }
      }
    })
    .state('manage-agent-inventry', {
      url: '/manage-agent-inventry',
      templateUrl: './views/manage-agent-inventry.html',
      controller: 'operatorAnalyticsCtrl',
      data: {
        meta: {
          'title': 'Manage Agent Inventry',
          'description': ''
        }
      }
    })
    .state('operator-payout-history', {
      url: '/operator-payout-history?page&to_date&from_date&sort_value&reverse&filter_date_type',
      templateUrl: './views/operator-payout-history.html',
      controller: 'operatorPayoutCtrl',
      data: {
        meta: {
          'title': 'Payout History',
          'description': ''
        }
      }
    })
    .state('on-my-site', {
      url: '/on-my-site',
      templateUrl: './views/on-my-site.html',
      controller: 'onMySiteCtrl',
      data: {
        meta: {
          'title': 'On My Site',
          'description': ''
        }
      }
    })
    .state('operator-referrer', {
      url: '/operator-referrer',
      templateUrl: './views/operator-traffic-source.html',
      controller: '',
      data: {
        meta: {
          'title': 'Operator Referrer',
          'description': ''
        }
      }
    })
    .state('operator-payout-detail', {
      url: '/operator-payout-detail?payment_detail_id&payment_no',
      templateUrl: './views/operator-payout-detail.html',
      controller: 'operatorPayoutCtrl',
      data: {
        meta: {
          'title': 'Payout Detail',
          'description': ''
        }
      }
    })
    .state('about-us', {
      url: '/about-us',
      templateUrl: './views/about-us.html',
      controller: 'aboutUsCtrl',
      data: {
        meta: {
          'title': 'About Us',
          'description': 'Heli was founded in 2015 by friends who wanted to go heliskiing. Our mission is to unite through adventure. Life is too short for lift lines.',
          'image': 'https://heli.life/app/images/about-banner.jpg'
        }
      }
    })
    .state('operatordoc', {
      url: '/onmysite-setting',
      templateUrl: './views/consumer-faq.html',
      controller: 'onMySiteSettingsCtrl',
      data: {
        meta: {
          'title': 'On My Site'
        }
      }
    })

    .state('agent-signup', {
      url: '/agent-signup',
      templateUrl: './views/agent-signup.html',
      controller: 'agentSignupCtrl',
      data: {
        meta: {
          'title': 'Agent',
          'description': 'Become an agent'
        }
      }
    })
    .state('new-route-white-label-package', {
      url: '',
      data: {
        meta: {
          'title': 'My Profile',
          'description': 'Find your heli social profile.'
        }
      }
    })
    .state('new-route-white-label/:package_name', {
      url: '',
      data: {
        meta: {
          'title': 'My Profile',
          'description': 'Find your heli social profile.'
        }
      }
    })
    .state('community', {
      url: '/community',
      templateUrl: './views/community-listing.html',
      controller: '',
      data: {
        meta: {
          'title': 'Community',
          'description': 'Find World best providers to bring you adventure packages, your Friends, Brands and Ambassadors.'
        }
      }
    })
    .state('sitemap', {
      url: '/sitemap',
      templateUrl: './views/sitemap.html',
      controller: 'sitemapController',
      data: {
        meta: {
          'title': 'Sitemap',
          'description': 'Sitemap for Heli.'
        }
      }
    })
    .state('manual-guest-booking', {
      url: '/manual-guest-booking?nightly_slot_id&start_date&end_date',
      templateUrl: './views/add-manual-booking.html',
      controller: 'operatorEditSlotCtrl',
      controllerAs: 'vm',
      data: {
        meta: {
          'title': 'Add Guest',
          'description': ''
        }
      }
    })
    .state('expired-slots', {
      url: '/expired-slots?package_id',
      templateUrl: './views/operator-expired-slots.html',
      controller: 'expiredSlotsController',
      data: {
        meta: {
          'title': 'Expired Slots',
          'description': 'List of Expired slots.'
        }
      }
    })
    .state('open-response', {
      url: '/open-response/:id',
      templateUrl: './views/open-response.html',
      controller: 'openResponseController',
      data: {
        meta: {
          'title': 'Response',
          'description': 'Check response.'
        }
      }
    })
    .state('myprofile', {
      url: '/{myusername}',
      templateUrl: './views/user-feed.html',
      controller: 'userFeedProfileCtrl',
      data: {
        meta: {
          'title': 'My Profile',
          'description': 'Find your heli social profile.'
        }
      },
      resolve: {
        showPage: ['$stateParams', '$state', '$rootScope', '$location', function ($stateParams, $state, $rootScope, $location) {
          if ($stateParams.myusername === '') {
            $state.go('home');
          }
        }]
      }
    })
    .state('slot-share', {
      url: '/{myusername}/:slot_id',
      templateUrl: './views/user-feed.html',
      controller: 'userFeedProfileCtrl',
      data: {
        meta: {
          'title': 'My Profile',
          'description': 'Find your heli social profile.'
        }
      },
      resolve: {
        showPage: ['$stateParams', '$state', '$rootScope', function ($stateParams, $state, $rootScope) {
          if ($stateParams.myusername === '') {
            $state.go('home');
          }
        }]
      }
    });
}]);
