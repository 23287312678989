/* eslint-disable no-redeclare */
/* eslint-disable no-cond-assign */
/* eslint-disable no-constant-condition */
/* eslint-disable no-undef */
/* eslint-disable no-tabs */

'use strict';

/**
	 * @ngdoc function
	 * @name myCartCtrl
	 * @description
	 * # myCartCtrl manage the user radar
	 * Controller of the heliApp
	 */

angular.module('heliApp').controller('myCartCtrl',[
  '$scope',
  '$rootScope',
  '$location',
  'userRegisterService',
  'userAdventureDetailService',
  'bookingService',
  '$timeout',
  '$cookies',
  '$state',
  '$filter',
  'CONST_HELPER',
  'MESSAGE_HELPER',
  'taxesService',
  'rootScopeService', myCartCtrl]);

function myCartCtrl (
  $scope,
  $rootScope,
  $location,
  userRegisterService,
  userAdventureDetailService,
  bookingService,
  $timeout,
  $cookies,
  $state,
  $filter,
  CONST_HELPER,
  MESSAGE_HELPER,
  taxesService,
  rootScopeService
) {
  var vm = this;
  // eslint-disable-next-line camelcase
  var cartId = $cookies.get('cart_id');
  var token = $scope.user.token ? $scope.user.token : '';
  var radarPackageId = $cookies.get('addToRadarPackageId'); var appliedCoupon;
  $scope.hidePopup('#session-timeout');
  document.getElementsByTagName('body')[0].classList.remove('modal-open');

  $scope.cart ={};
  $scope.taxes ={};

  // when user is not logged in and he clicked on add to cart. then first get user signed in and add package to radar.
  if (radarPackageId && radarPackageId !== 'null') {
    var item = {
      package_id: radarPackageId,
      userToken: $scope.user.token
    };
    addToUserRadarList(item);
  }

  if($location.path() === '/mycart'){
    $rootScope.partialCheckedSlot = [];
		$rootScope.depositPackages = [];
  }

  $scope.emailPattern = /^([a-zA-Z0-9_.-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  // scope variable
  // $rootScope.myCartLength=0;
  vm.myCart = [];
  vm.CartTotal = CONST_HELPER.ZERO;
  vm.CartFullTotal = CONST_HELPER.ZERO;
  vm.processingFee = CONST_HELPER.ZERO;
  vm.previousPrice = CONST_HELPER.ZERO;
  vm.CartGrandTotal = CONST_HELPER.ZERO;
  vm.totalCouponDiscount = CONST_HELPER.ZERO;
  // vm.taxPercent=12.5;
  vm.openCouponBox = false;
  vm.isUserLoggedIn = !!$scope.user.token;
  vm.couponAppiled = false;
  vm.appliedCouponDetails = {};
  vm.anyItemOutOfStock = false;
  vm.showAlertMsg = false;
  vm.Ffixed = CONST_HELPER.PROCESS_FEE.Ffixed;
  vm.Fpercent = CONST_HELPER.PROCESS_FEE.Fpercent;
  vm.taxAmount1 = CONST_HELPER.ZERO;
  $rootScope.selectedGuest = '1';
  $rootScope.selectedCurrencyCode = $rootScope.localCurrencyCode;
  $rootScope.selectedCurrencyMyCart = $rootScope.localCurrencyCode;
	$scope.depositPackages = [];

  $scope.guestDetail = {};
  $scope.generalGuest = {};
  $scope.acknowledgeAgreeError = false;
  $scope.guestDetailsInformationError = false;
  $rootScope.initializePhoneField("#generalGuestPhone");
  $rootScope.userLocalCountry = null;
  // #function to get the cart list...
  function getCartList (applyPC, callback) {
    $scope.showLoader();
    userAdventureDetailService.getCartItemslist(cartId, token).then(function (res) {
      if (!res || !res.data || $rootScope.releasingSeats) {
        getCartList();
        return;
      }
      if (res.data.code === '230') {
        $scope.hideLoader();
        return false;
      }
      if (res.data.message = 'Success') {
        $scope.cart = res.data.cart;
        $scope.taxes = res.data;

        vm.myCart = res.data;
        $scope.guests_detail = [];
        var totalGuests = 0;
        if(vm.myCart.cart_slot_details && vm.myCart.cart_slot_details.length){
          $scope.localCurrencyCodeOperator = vm.myCart.cart_slot_details[0].local_currency_code;
          $scope.currencyCodeForMyCard = vm.myCart.cart_slot_details[0].local_currency_code;
          if(vm.myCart.cart_slot_details[0].package.package_case!=1 || vm.myCart.cart_slot_details[0].package.lodging_status==0 || !vm.myCart.cart_slot_details[0].slot_lodging_details.length){
            totalGuests = vm.myCart.cart_slot_details[0].slot_adult_quantity;
          }else if(vm.myCart.cart_slot_details[0].package.package_case==1 && vm.myCart.cart_slot_details[0].package.lodging_status==1 && vm.myCart.cart_slot_details[0].slot_lodging_details.length){
            vm.myCart.cart_slot_details[0].slot_lodging_details.map(function (sl) {
              totalGuests += sl.quantity;
            });
          }
          for (var i = 0; i < totalGuests-1; i++) {
            $scope.guests_detail.push({
              guest_adult_child: 1,
              guest_tbo: 0
            })
          }
        }
        if(vm.isUserLoggedIn){
          $scope.generalGuest.first_name = $rootScope.user.first_name;
          $scope.generalGuest.last_name = $rootScope.user.last_name;
          $scope.generalGuest.email = $rootScope.user.email;
          if($rootScope.user.phone && $rootScope.user.phone.toString()[0] != "+"){
            $("#generalGuestPhone").intlTelInput("setNumber", '+' + $rootScope.user.phone.toString());
          }else{
            if(!!$rootScope.user.phone){
              $("#generalGuestPhone").intlTelInput("setNumber", $rootScope.user.phone.toString());
            }else{
              $("#generalGuestPhone").intlTelInput("setNumber", '');
            }
          }
        }
        $scope.waiverAvailable = vm.myCart.cart_slot_details[0] && vm.myCart.cart_slot_details[0].package ? vm.myCart.cart_slot_details[0].package.waiver : null;
        var previousCartLength = angular.copy($rootScope.myCartLength) || vm.myCart.cart_slot_details.length;
        $rootScope.myCartLength = res.data.cart_slot_details.length;
        rootScopeService.setValue("myCartLength", $rootScope.myCartLength);
        vm.pageLoaded = true;
        if (res.data.cart_details && res.data.cart_details.id) {
          var now = new Date();
          var exp = new Date(now.getFullYear() + 1, now.getMonth(), now.getDate());
          saveCookie('cart_id', res.data.cart_details.id, exp);
          cartId = res.data.cart_details.id;
        }
        $scope.getSlotUsdRate(vm.myCart.cart_details.local_currency_code, $scope.selectedCurrencyMyCart, function (currencyRate) {
          $scope.selectedCurrencyMyCartRate = currencyRate;
        });
        appliedCoupon = $cookies.get('couponApplied');
        // check if new item is added into cart and coupon is already appilied on rest call removecoupn function to apply on new cart.
        if (previousCartLength !== $rootScope.myCartLength && $rootScope.myCartLength > 0) {
          if (appliedCoupon && appliedCoupon !== '' && applyPC) {
            vm.previousPrice = 0;
            var appliedCouponData = {
              promo_code_id: $cookies.get('coupon_id') || appliedCoupon,
              cart_id: $cookies.get('cart_id') || cartId,
              previousPrice: vm.myCart.cart_details.base_amount
            };
            vm.removeCoupon(appliedCouponData);
          }
          updateLocalCurrencyCode(true);	// update localCurrencycode if new package is being added...
        } else {
          if (vm.myCart.cart_details && vm.myCart.cart_details.discount_amount > 0 && applyPC) {	// if promo code is applied on cart
            vm.previousPrice = 0;
            var appliedCouponData = {
              promo_code_id: $cookies.get('coupon_id') || appliedCoupon,
              cart_id: $cookies.get('cart_id') || cartId,
              previousPrice: vm.myCart.cart_details.base_amount
            };
            vm.removeCoupon(appliedCouponData, function () {			// to handle cart package price change from operator... if coupon is already applied then first remove it and then again apply it for updated changes...
              $scope.showLoader();
              calculateTotal(vm.myCart, false, function () {			// update the total amount after removing the applied coupon;
                if (appliedCoupon) { vm.applyCoupon(appliedCoupon); }	// then again apply coupon.
              }, 'couponRemoved');
            });
          } else {
            $scope.hideLoader();
          }

          calculateTotal(vm.myCart);
          updateLocalCurrencyCode();
        }
        // mark packages if selected partial payment and calculate partial price
        if($rootScope.partialCheckedSlot && $rootScope.partialCheckedSlot.length){
          $rootScope.partialCheckedSlot.forEach(function(partialPackage){
            vm.myCart.cart_slot_details.forEach(function(cartSlot){
              if(partialPackage.package_id == cartSlot.package_id){
                cartSlot.package.is_partial_checked = 1;
              }
            })
          })
          calculateTotal(vm.myCart, true);
        }

        if (callback) {
          callback();
        }
      } else if (response.data.code === '203') { // logout if admin de-activate account..
        $scope.logout();// unauthorised user
        $scope.hideLoader();
        $state.go('signin');
      }
    }, function () {
      $scope.hideLoader();
      $scope.showPopup('#serverErrModal');
    });
  };
  if (cartId || vm.isUserLoggedIn) {
    $scope.showLoader();
		if (!cartId && $scope.user) {
			userAdventureDetailService.getCartlistCount(cartId, $scope.user.token).then(function (res) {
				if (res && res.data && res.data.message === 'Success') {
					$rootScope.myCartLength = res.data.numFound;
          rootScopeService.setValue("myCartLength", $rootScope.myCartLength);
					cartId =  res.data.cartId;
					$cookies.put('cart_id', res.data.cartId);
					getCartList(true);	// call getCartList() initially...
				} else if (res && res.data && res.data.code === 401) { // invalid token or Guest user
					$scope.logout();
				}
			}, function () {
				$scope.showPopup('#serverErrModal');
			});
		} else {
			getCartList(true);	// call getCartList() initially...
		}
    vm.pageLoaded = false;
  } else {
    $rootScope.myCartLength = 0;
    rootScopeService.setValue("myCartLength", $rootScope.myCartLength);
    vm.pageLoaded = true;		// set it true when user have no item in cart...
  }

  $scope.userCountry = {};
  $scope.getAllCountryName = function () {
    userRegisterService.getAllCountry().then(function (data) {
      if (data.data.code === CONST_HELPER.API_RESPONSE.OK) {
        $scope.allcountryName = data.data.data;
      }
    });
  };
  $scope.getAllCountryName();
  function updateLocalCurrencyCode (updateCartCurrency) {			// update the localcurrency with pa
    $timeout(function () {
      if ($rootScope.myCartLength === 0) { return; }

      if (vm.myCart && vm.myCart.cart_slot_details.length > 0) {
        $rootScope.selectedCurrencyCode = $rootScope.localCurrencyCode;
        $rootScope.selectedCurrencySymbol = $scope.currencySymbol[$rootScope.selectedCurrencyCode];
        if (updateCartCurrency) {
          calculateTotal(vm.myCart);
        }
        $timeout(function () { // for custom plugin
          $('.selectpicker').selectpicker();
        }, 100);
      } else {
        updateLocalCurrencyCode();
      }
    }, 10);
  }

  // get total currency list...
  userAdventureDetailService.getCurrency().then(function (res) {
    if (res.data.code === '200') {
      vm.Currencies = res.data.data;
      vm.Currencies.map(function (val) {
        val.symbol = ' (' + $scope.currencySymbol[val.code] + ')';
      });
    }
  }, function () {

  });
  vm.convertCurrency = function (selectedCurrency) {
    $scope.showLoader();
    var data = {
      'cart_id': cartId,
      'my_currency_code': vm.myCart.cart_slot_details[0].package_currency_code
    };
    userAdventureDetailService.convertCartCurrency(data).then(function (res) {
      if (!res || !res.data) { vm.convertCurrency(selectedCurrency); }

      if (res.data.code === '200') {
        $scope.selectedCurrencyMyCart = selectedCurrency;
        getCartList();
      }
    }, function () {
      $scope.hideLoader();
    });
  };
  $scope.partialCheckedSlot = [];
  $scope.updateTotalAmount = function (item) {
    if (item.package.is_partial_checked) {
      var slotObj = {
        package_slot_id: item.package_slot_id,
        is_partial_checked: item.package.is_partial_checked,
        package_id: item.package_id
      };
			var depositFlags = {
				booking_slot_id : item.id,
				is_deposit: item.package.is_partial_checked
			}
			$scope.depositPackages.push(depositFlags);
      $scope.partialCheckedSlot.push(slotObj);
    } else {
      var obj = $scope.partialCheckedSlot.find(function (pcl) {
        return pcl.package_slot_id === item.package_slot_id;
      });
      var index = $scope.partialCheckedSlot.indexOf(obj);
      $scope.partialCheckedSlot.splice(index, 1);

			var objDepositFlag = $scope.depositPackages.find(function (depositFlag) {
        return depositFlag.booking_slot_id === item.id;
      });
			$scope.depositPackages.splice($scope.depositPackages.indexOf(objDepositFlag), 1);
    }

    $timeout(function () {
      calculateTotal(vm.myCart, true);
    });
  };

  $rootScope.tag.keyword = '';
  // calculate the cart Total amounts...
  function calculateTotal (cart, CalculateTax, callback, couponRemoved) {
    vm.CartTotal = CONST_HELPER.ZERO;
    vm.CartSubTotal = CONST_HELPER.ZERO;
    vm.CartFullTotal = CONST_HELPER.ZERO;
    vm.promoCodeGrandTotal = CONST_HELPER.ZERO;
    vm.CartGrandTotal = CONST_HELPER.ZERO;
    vm.previousPrice = CONST_HELPER.ZERO;
    vm.totalCouponDiscount = CONST_HELPER.ZERO;
    vm.finalCouponDiscount = CONST_HELPER.ZERO;
    vm.finalStayDiscount = CONST_HELPER.ZERO;
    vm.anyItemOutOfStock = false;
    vm.taxAmount1 = CONST_HELPER.ZERO;
    var hideLoader = true;
    cart.cart_slot_details.forEach(function loop (item, key) {
      if (loop.stop) { return; }
      var slotCurrency = cart.cart_slot_details[0].local_currency_code;
      if (slotCurrency !== item.local_currency_code) {
        if ($scope.user && $scope.user.currency_code) {
          $rootScope.selectedCurrencyCode = $scope.user.currency_code;
        } else {
          $rootScope.selectedCurrencyCode = slotCurrency;
        }
        vm.convertCurrency($rootScope.selectedCurrencyCode);
        loop.stop = true;
        hideLoader = false;
      }

      // get the package currency rate of package currency vs local currency...
      item.localCurencyRate = getLocalCurrnencyRate(item);

      var total = 0;
      var promoCodeTotal = 0;
      // for case A + lodging & case B;
      if (item.slot_lodging_details && item.slot_lodging_details.length) {
        item.total_guests = 0;
        item.slot_lodging_details.map(function (sl) {
          if (sl.slot_lodging || sl.nightly_slot) {
            // total += sl.is_private ? sl.units*sl.slot_lodging.private_price_local : sl.quantity*sl.slot_lodging.price_local;
            total += getChargedPrivatePrice(sl, item);
          } else {
            total += getChargedPrivatePrice(sl, item);
            item.lodgingDeleted = true;
            vm.anyItemOutOfStock = true;
          }
          item.total_guests += sl.quantity;
        });
      } else {	// for case C & case A + no lodging...
        if (item.nightly_slot) { // case C and case A without lodging
          total = getNightlyPrice(item);
        } else {
          if (item.package.package_case === CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE) {
            total = total + item.package_slots.adult_price_local;
          } else {
            total = total + item.slot_adult_quantity * item.package_slots.adult_price_local;
          }
        }
        item.total_guests = item.slot_adult_quantity;
      }
      promoCodeTotal = angular.copy(total);
      // if nightly discount is applied
      if (item.nightly_discount) {
        promoCodeTotal = promoCodeTotal - item.nightly_discount;
      }
      item.addons_details.map(function (addon) {
        total = total + (addon.package_addon ? addon.package_addon_adult_quantity * addon.package_addon.adult_price_local : CONST_HELPER.ZERO);
      });
      if (item.is_promo_code === CONST_HELPER.ONE && !couponRemoved) {		// if coupon applied
        vm.couponAppiled = true;
        if (item.package.is_partial_checked) {
          vm.CartTotal = vm.CartTotal + item.package.partial_total_amount;
          // vm.totalCouponDiscount = vm.totalCouponDiscount + item.discount_amount * item.package.amount_percentage / 100;
          vm.totalCouponDiscount = vm.totalCouponDiscount + item.discount_amount;
          vm.CartSubTotal = vm.CartSubTotal + (item.package.partial_total_amount ? item.package.partial_total_amount : 0);
        } else {
          vm.CartTotal = vm.CartTotal + total - item.discount_amount;
          vm.finalCouponDiscount = vm.finalCouponDiscount + item.discount_amount;
          vm.totalCouponDiscount = vm.totalCouponDiscount + item.discount_amount;
          vm.CartSubTotal = vm.CartSubTotal + total;
        }
        vm.CartFullTotal = vm.CartFullTotal + total - item.discount_amount;
        vm.promoCodeGrandTotal = vm.promoCodeGrandTotal + promoCodeTotal;
        item.total = item.is_genric === CONST_HELPER.ZERO ? Number((total - item.discount_amount).toFixed(2)) : total;
        item.previousTotal = item.is_genric === CONST_HELPER.ZERO ? total : CONST_HELPER.ZERO;
        vm.couponBox = '';
        vm.appliedCouponDetails = {
          is_applied: CONST_HELPER.ONE,
          promo_code_id: item.promo_code.id,
          is_genric: item.is_genric,
          promo_code: item.promo_code.code
        };
        vm.appliedCoupon = item.promo_code.code;
        vm.openCouponBox = false;
      } else {													// else not applied
        if (item.package.is_partial_checked) {
          vm.CartTotal = vm.CartTotal + item.package.partial_amount;
          vm.CartSubTotal = vm.CartSubTotal + (item.package.partial_total_amount ? item.package.partial_total_amount : 0);
        } else {
          vm.CartTotal = vm.CartTotal + total;
          vm.CartSubTotal = vm.CartSubTotal + total;
        }
        vm.CartFullTotal = vm.CartFullTotal + total;
        vm.promoCodeGrandTotal = vm.promoCodeGrandTotal + promoCodeTotal;
        item.total = total;
      }
      vm.previousPrice = vm.previousPrice + total;
      // if nightly discount is applied
      if (item.nightly_discount && item.package_discounts) {
        if (item.nightly_discount_original === undefined) {
          // Store original discount amount only once
          item.nightly_discount_original = item.nightly_discount;
        }

        if (item.package.is_partial_checked) {
          if (item.package.deposit_type == CONST_HELPER.DEPOSIT_TYPE.PERCENTAGE) {
            // Discount needs to be decreased by the same % as deposit
            item.nightly_discount *= item.package.amount_percentage / 100;
          } else {
            // Discount will be applied to the final balance payment
            item.nightly_discount = 0;
          }
        } else {
          // Restore original discount value
          if (item.nightly_discount_original !== undefined) {
            item.nightly_discount = item.nightly_discount_original;
          }
        }

        vm.finalStayDiscount = vm.finalStayDiscount + item.nightly_discount;
        vm.CartFullTotal = vm.CartFullTotal - item.nightly_discount;
        vm.CartTotal = vm.CartTotal - item.nightly_discount;
        vm.previousPrice = vm.previousPrice - item.nightly_discount;
      }

      // chcek if slots has partial payment option, calculate the partial amount to be paid.
      if (item.package.is_partial === CONST_HELPER.ONE) {
        switch (String(item.package.deposit_type)) {
          case CONST_HELPER.DEPOSIT_TYPE.PERCENTAGE: 				// IF DEPOSIT TYPE IS PERCENTAGE
            if (item.is_promo_code === CONST_HELPER.ONE && item.is_genric === CONST_HELPER.ZERO) {
              item.package.partial_total_amount = item.previousTotal * item.package.amount_percentage / CONST_HELPER.HUNDRED;
            } else {
              item.package.partial_total_amount = item.total * item.package.amount_percentage / CONST_HELPER.HUNDRED;
            }
            item.package.partial_amount = item.base_amount * item.package.amount_percentage / CONST_HELPER.HUNDRED;
            break;
          case CONST_HELPER.DEPOSIT_TYPE.AMOUNT: // IF DEPOSIT TYPE IS AMOUNT
            /**
             * When an operator creates a package with an Amount deposit instead of a percentage, then it should work according to the following:
             * - By Seat Packages: The flat rate should be charged per guest
             * - By Accommodation: The flat rate should be charged per room
             * - Flat Rate Package: The flat rate should be charged once for the entire package
             *
             * It charges the flat deposit amount according to the above rules for multiday packages, single day, and nightly or daily rates.
             */
            item.package.partial_amount = item.package.partial_total_amount = item.package.amount_percentage;

            switch (item.package.package_case) {
              case CONST_HELPER.PACKAGE_CASE.PER_SEAT:
                // Multiply by the number of guests
                var guestsCount = 0;
                if (
                  (item.package.lodging_status == 1)
                  &&
                  (item.slot_lodging_details.length > 0)
                ) {
                  guestsCount = item.total_guests;
                } else {
                  guestsCount = item.slot_adult_quantity;
                }
                item.package.partial_amount *= guestsCount;
                item.package.partial_total_amount *= guestsCount;

              break;

              case CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION:
                // Multiply by the number of units
                var unitsCount = 0;
                for (var i = 0; i < item.slot_lodging_details.length; ++i) {
                  unitsCount += item.slot_lodging_details[i].quantity;
                }
                item.package.partial_amount *= unitsCount;
                item.package.partial_total_amount *= unitsCount;

                break;
            }

            break;
        }
      }
      claculateTaxAmount(item, key, CalculateTax);
      // convertCurrency(item);
      if (!item.nightly_slot && (!item.package_slots || !item.package_slot)) {		// check if package slot is deleted
        item.slotDeleted = true;
        vm.anyItemOutOfStock = true;
        return;
      } else if (vm.anyItemOutOfStock) {
        return;	// return if lodging is deleted...
      }
      // check if seats are out of stock.
      outofstockCheck(item, function (isOutOfStock) {
        if (isOutOfStock) {
          item.outOfStock = true;
          item.total = total;
          vm.anyItemOutOfStock = true;
        }
      });

      // check if operator is deactivated...
      operatorDeactivatedCheck(item, function (operatorDeactivated) {
        if (operatorDeactivated) {
          item.operatorDeactivated = true;
          item.total = total;
          vm.anyItemOutOfStock = true;
        }
      });

      if (item.package.status === 2) { vm.anyItemOutOfStock = true; }
      // check if added slot is expired.
      var slotStartDate = new Date(item.package_slots.from_date);
      var currentDate = new Date();
      var diffDays = Math.floor((Date.UTC(slotStartDate.getFullYear(), slotStartDate.getMonth(), slotStartDate.getDate()) - Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())) / (1000 * 60 * 60 * 24));
      if (diffDays < 0) {
        item.expired = true;
        vm.anyItemOutOfStock = true;
      } else {
        item.expired = false;
      }
    });

    if (vm.CartFullTotal === vm.previousPrice) { // CAHEK COUPON IS APPLIED OR NOT
      vm.previousPrice = 0;
    }

    if (callback) {			// return callback
      callback();
    } else if (hideLoader) {
      $scope.hideLoader();
    }
  }
  // get the nighly price for case c and case A without lodging.
  function getNightlyPrice (item) {
    if (item.nightly_slot) {
      item.slot_lodging = item.nightly_slot;
    }
    // calcualte each day price for nightly booking...
    if (item.nightly_slot && item.nightly_slot.calendar) {
      if (typeof (item.slot_lodging.calendar) === 'string') {
        item.slot_lodging.calendar = JSON.parse(item.slot_lodging.calendar);
      }
      item.package_slots.from_date = moment($filter('changeDateObject')(item.package_slots.from_date)).format('YYYY-MM-DD');
      item.package_slots.to_date = moment($filter('changeDateObject')(item.package_slots.to_date)).format('YYYY-MM-DD');
      var total = CONST_HELPER.ZERO;
      item.slot_lodging.calendar.map(function (eachDates) {
        var slot_eachDate = moment(eachDates.date);
        if (
          (
            slot_eachDate.isSameOrAfter(item.package_slots.from_date) &&
            slot_eachDate.isSameOrBefore(item.package_slots.to_date) &&
            item.package.daily_rate_activity == 1
          )
          ||
          (
            slot_eachDate.isSameOrAfter(item.package_slots.from_date) &&
            slot_eachDate.isBefore(item.package_slots.to_date) &&
            item.package.daily_rate_activity != 1
          )
          ||
          (
            (item.package_slots.from_date === item.package_slots.to_date)
            &&
            slot_eachDate.isSame(item.package_slots.to_date)
          )
        ) {
          if (item.package.package_case === CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE) {
            total += parseInt(eachDates.price);
          } else {
            total += (item.slot_adult_quantity * +eachDates.price);
          }
        }
      });
      return total * item.localCurencyRate;
    }
  }

  // get the private price whether user is charged extra or not.
  function getChargedPrivatePrice (sl, item) {
    if (sl.nightly_slot) {
      sl.slot_lodging = sl.nightly_slot;
    }
    // calcualte each day price for nightly booking...
    if (sl.nightly_slot && sl.nightly_slot.calendar) {
      if (typeof (sl.slot_lodging.calendar) === 'string') {
        sl.slot_lodging.calendar = JSON.parse(sl.slot_lodging.calendar);
      }
      var total = 0;
      item.package_slots.from_date = moment($filter('changeDateObject')(item.package_slots.from_date)).format('YYYY-MM-DD');
      item.package_slots.to_date = moment($filter('changeDateObject')(item.package_slots.to_date)).format('YYYY-MM-DD');
      if (sl.booking_lodging_rooms && sl.booking_lodging_rooms.length) {
        sl.booking_lodging_rooms.map(function (br) {
          sl.slot_lodging.calendar.map(function (eachDates) {
            var slot_eachDate = moment(eachDates.date);
            var isPayableDay =
              // Day >= min adventure date
              slot_eachDate.isSameOrAfter(item.package_slots.from_date)
              &&
              (
                // On-day adventure: We should include single day into total price calculation
                (
                  (item.package_slots.from_date === item.package_slots.to_date) &&
                  slot_eachDate.isSameOrBefore(item.package_slots.to_date)
                )
                ||
                // Multi-day adventure: We shouldn't include last day of adv into total price calculation
                (
                  (item.package_slots.from_date !== item.package_slots.to_date) &&
                  slot_eachDate.isBefore(item.package_slots.to_date)
                )
                ||
                (
                  (item.package_slots.from_date !== item.package_slots.to_date) &&
                  slot_eachDate.isSameOrBefore(item.package_slots.to_date) &&
                  item.package.daily_rate_activity == 1
                )
              );

            if (isPayableDay) {
              console.log(eachDates.date);
              // total = total + sl.quantity * eachDates.price;
              var roomObj = JSON.parse(br.rooms_guest);
              if (roomObj.type === 'private' && roomObj.guest <= sl.slot_lodging.lodging.min_private_guests) {
                total += roomObj.guest * eachDates.private_price; 	// total price including private price...
              } else {
                total += (roomObj.guest || roomObj) * eachDates.price; 	// total price not including private price...
              }
            }
          });
        });
        return total * item.localCurencyRate;
      } else { // case B
        var total = CONST_HELPER.ZERO;
        sl.slot_lodging.calendar.map(function (eachDates) {
          var slot_eachDate = moment(eachDates.date);
          if (
            (
              slot_eachDate.isSameOrAfter(item.package_slots.from_date) &&
              slot_eachDate.isBefore(item.package_slots.to_date)
            )
            ||
            (
              slot_eachDate.isSameOrAfter(item.package_slots.from_date) &&
              slot_eachDate.isSameOrBefore(item.package_slots.to_date) &&
              moment(item.package_slots.from_date).isSame(item.package_slots.to_date)
            )
            ||
            (
              slot_eachDate.isSameOrAfter(item.package_slots.from_date) &&
              slot_eachDate.isSameOrBefore(item.package_slots.to_date) &&
              item.package.daily_rate_activity == 1
            )
          ) {
            total += (sl.quantity * eachDates.price);
          }
        });
        return total * item.localCurencyRate;
      }
    }
    if (sl.is_private && sl.booking_lodging_rooms.length) {
      var privatePrice = 0;
      sl.booking_lodging_rooms.map(function (br) {
        var roomObj = JSON.parse(br.rooms_guest);
        if (roomObj.guest <= sl.slot_lodging.lodging.min_private_guests) {
          privatePrice += roomObj.guest * sl.slot_lodging.private_price_local; 	// total price including private price...
        } else {
          privatePrice += (roomObj.guest * sl.slot_lodging.price_local); 	// total price not including private price...
        }
      });
      return privatePrice;
    } else {
      return (sl.quantity * sl.slot_lodging.price_local); 	// total price not including private price...
    }
  }

  // function to return the package currency rate of package currency vs local currency...
  function getLocalCurrnencyRate (slot) {
    if (slot.slot_lodging_details.length) {
      if (slot.slot_lodging_details[0].nightly_slot) {
        var slotLodging = slot.slot_lodging_details[0].nightly_slot;
      } else {
        var slotLodging = slot.slot_lodging_details[0].slot_lodging;
      }
      if (slotLodging.price) {
        return slotLodging.price_local / slotLodging.price;
      } else {
        return slotLodging.private_price_local / slotLodging.private_price;
      }
    } else if (slot.nightly_slot) {
      return slot.nightly_slot.price_local / slot.nightly_slot.price;
    } else {
      return slot.package_slots.adult_price_local / slot.package_slots.adult_price;
    }
  }

  // check if slot is get expired
  function outofstockCheck (slot, callback) {
    if (slot.package.package_case === 3 || slot.package.lodging_status === 0 || (slot.package.lodging_status === 1 && !slot.slot_lodging_details.length)) {
      callback(slot.package_slots && slot.package_slots.available_seat <= 0);
    } else if (slot.package.package_case === 2) {
      var slotHasExpiredSlot = false;
      slot.slot_lodging_details.map(function (sl) {
        if (sl.quantity > sl.slot_lodging.available_units) {
          slotHasExpiredSlot = true;
        }
      });
      callback(slotHasExpiredSlot);
    } else if (slot.package.package_case === 1 && slot.package.lodging_status === 1 && slot.slot_lodging_details.length) {
      callback(slot.package_slots && slot.package_slots.available_seat < slot.total_guests);
    }
  }

  function operatorDeactivatedCheck (slot, callback) {
    var isOperatorDeactivated = slot.user.status_id === 2;
    callback(isOperatorDeactivated);
  }

  // calculate the processing charge....
  function getProcessingFee () {
    function getDurationInDays(config) {
      var diffInDays = (+config.to - +config.from) / (1000 * 3600 * 24);
      if (diffInDays === 0) {
        // Duration of any adventure couldn't be 0, at least 1 day or night
        diffInDays = 1;
      } else if (!config.isNightly) {
        // For not-nightly slots we should calculate last day too
        diffInDays += 1;
      }

      return diffInDays;
    }

    var totalCartTax = 0;
    var cartSubTotalPrice = 0.0;
    var cartTaxesPrice = 0.0;
    vm.myCart.cart_slot_details.map(function (item, key) {
      var pckTotal = 0;
      var pckTax = 0;
      if (item.is_promo_code === 1 && item.is_genric === 0) {
        pckTotal = item.total;
      } else {
        pckTotal = item.base_amount - item.discount_amount;
      }
      // if nightly discount is applied
      if (item.nightly_discount) {
        pckTotal = pckTotal - item.nightly_discount;
      }

      pckTax = item.total_tax ? item.total_tax : 0;
      if (item.package.is_partial_checked) {
        pckTotal = item.package.partial_amount - item.nightly_discount;
        var taxPercent = isApplyDomesticTax(item.operator_tax_cloud);
        pckTax = (pckTotal * taxPercent / CONST_HELPER.HUNDRED);
        totalCartTax = totalCartTax + pckTax;
        pckTotal = Number(pckTotal.toFixed(2));
      } else {
        totalCartTax = totalCartTax + pckTax;
      }
      cartSubTotalPrice += pckTotal;
      var bookingDuration = {};
      if (item.package.booking_type == 1) {
        bookingDuration = {
          from: new Date(item.package_slot.from_date),
          to: new Date(item.package_slot.to_date),
          isNightly: true,
        }
      } else {
        bookingDuration = {
          from: new Date(item.from_date),
          to: new Date(item.to_date),
          isNightly: item.nightly_slot_id > 0,
        }
      }

      var packagePrice = !!item.package.is_partial_checked ? item.package.partial_amount : item.base_amount;
      if (item.nightly_discount) {
        packagePrice -= item.nightly_discount;
      }
      if (!item.package.is_partial_checked && item.is_promo_code === 1) {
        packagePrice -= item.discount_amount;
      }
      item.tax_amount = taxesService.calculateTaxesSummaryPrice({
        taxes: item.package.operator_taxes,
        packagePrice: packagePrice,
        isDeposit: !!item.package.is_partial_checked,
        isCollectPercentageTaxesOnTheDeposit: item.package.is_collect_percentage_taxes_on_the_deposit,
        personsCount: parseInt(item.total_guests),
        duration: getDurationInDays(bookingDuration),
        userCountryId: parseInt($scope.user.country_id)
      });
      cartTaxesPrice += item.tax_amount;
    });

    // Use tax price from server instead of calculated here

    totalCartTax = cartTaxesPrice;
    vm.total_Cart_tax = totalCartTax;
    $scope.cart.subTotalPrice.amount = cartSubTotalPrice;
    $scope.cart.taxesPrice.amount = cartTaxesPrice;
		var cartSubTotalAndTaxesPrice = 0;
		var totalPrice = 0;
		if ($scope.user.country_id || $rootScope.userLocalCountry) {
			cartSubTotalAndTaxesPrice = $scope.cart.subTotalPrice.amount + $scope.cart.taxesPrice.amount;
      var calculateFee = cartSubTotalAndTaxesPrice * vm.Fpercent;
			totalPrice = cartSubTotalAndTaxesPrice > 0 ? (cartSubTotalAndTaxesPrice + calculateFee) : 0;
		} else {
			cartSubTotalAndTaxesPrice = $scope.cart.subTotalPrice.amount;
			totalPrice = cartSubTotalAndTaxesPrice > 0 ? cartSubTotalAndTaxesPrice : 0;
		}
		$scope.cart.grandTotalPrice.amount = totalPrice;
		$scope.cart.processingFeePrice.amount = vm.processingFee = totalPrice - cartSubTotalAndTaxesPrice;
		vm.CartGrandTotal = totalPrice;
		vm.processingFeeEach = (vm.processingFee / $rootScope.myCartLength).toFixed(2);

    $cookies.put('cartTaxAmount', vm.total_Cart_tax);
    $cookies.put('cartProcessingAmount', vm.processingFee);
  }
  // calculate the Tax amount ...
  function claculateTaxAmount (item, index, CalculateTax) {
    if (CalculateTax) {
      getProcessingFee();
      return false;
    }
    var taxPercent = isApplyDomesticTax(item.operator_tax_cloud);
    item.total_tax = Number(((item.base_amount - item.discount_amount - item.nightly_discount) * taxPercent / 100).toFixed(2));
    vm.taxAmount1 = vm.taxAmount1 + (item.base_amount - item.discount_amount - item.nightly_discount) * taxPercent / 100;
    getProcessingFee();
  }
  // get domestic or international price.
  function isApplyDomesticTax (operatorTax) {
    if (!operatorTax) { return 0; }
    if (!$rootScope.userLocalCountry && $cookies.get('user_country_id')) {
      $rootScope.userLocalCountry = JSON.parse($cookies.get('user_country_id'))
    }
    if (operatorTax.country_code == $rootScope.userLocalCountry) {
      return operatorTax.domestic_percent;		// apply domestictax
    } else {
      return operatorTax.international_percent; // apply internation tax.
    }
  }
  // refresh cart;
  $scope.refreshCart = function () {
    getCartList();
  };

  // function to edit cart item
  vm.editCart = function (item) {
    if (item.nightly_slot) {
      $state.go('package-slot-addons-summary', { cart_id: item.cart_id, package_id: item.package_id, start_date: new Date(item.package_slots.from_date).getTime(), end_date: new Date(item.package_slots.to_date).getTime(), adults: item.total_guests || item.slot_adult_quantity });
    } else {
      $state.go('package-slot-addons-summary', { selected_slot_id: item.package_slot_id, cart_id: item.cart_id, adults: item.total_guests || item.slot_adult_quantity });
    }
  };

  // function to remove package from cart
  vm.removeFromCart = function (item) {
    vm.showAlertMsg = false;
    $scope.showLoader();
    var data = { cart_id: item.cart_id, slot_id: item.package_slot_id, package_id: item.package_id, nightly_slot_id: item.nightly_slot_id, booking_slot_detail_id: item.id };
    userAdventureDetailService.deleteCartItem(data).then(function (res) {
      if (res.data.message = 'Success') {
        var index = vm.myCart.cart_slot_details.indexOf(item);
        vm.myCart.cart_slot_details.splice(index, 1);
        if (vm.myCart.cart_slot_details.length === CONST_HELPER.ZERO) {
          $cookies.put('coupon_id', '');
          $cookies.put('couponApplied', '');
          $scope.hideLoader();
          $rootScope.myCartLength = CONST_HELPER.ZERO;
          rootScopeService.setValue("myCartLength", $rootScope.myCartLength);
          return;
        }
        calculateTotal(vm.myCart);							// update Total amount after user remove any package from cart.
        appliedCoupon = $cookies.get('couponApplied');
        if (appliedCoupon && appliedCoupon !== '') {				//	apply coupon if any, after user remove any package from cart.
          vm.previousPrice = 0;
          var appliedCouponData = {
            promo_code_id: $cookies.get('coupon_id') || appliedCoupon,
            cart_id: $cookies.get('cart_id') || cartId,
            previousPrice: vm.myCart.cart_details.base_amount
          };
          vm.removeCoupon(appliedCouponData, function () {			// to handle cart package price change from operator... if coupon is already applied then first remove it and then again apply it for updated changes...
            $scope.showLoader();
            calculateTotal(vm.myCart, false, function () {			// update the total amount after removing the applied coupon;
              if (appliedCoupon) { vm.applyCoupon(appliedCoupon); }	// then again apply coupon.
            }, 'couponRemoved');
          });
        }
        $scope.refreshCart();
        $scope.hideLoader();
        $rootScope.myCartLength = vm.myCart.cart_slot_details.length;			// update cart item count...
        rootScopeService.setValue("myCartLength", $rootScope.myCartLength);
      }
    }, function () {
      $scope.hideLoader();
      $scope.showPopup('#serverErrModal');
    });
  };
  vm.addToRadar = function (item) {
    var adventurePackage = {
      package_id: item.package_id,
      userToken: $scope.user.token
    };
    if (vm.isUserLoggedIn) {			// user logged in
      addToUserRadarList(adventurePackage, item);
    } else {
      saveCookie('addToRadarPackageId', adventurePackage.package_id);							// user not logged in
      $state.go('signin');
    }
  };
  vm.removeFromRadar = function (item) {
    $scope.showLoader();
    var adventurePackage = {
      package_id: item.package_id,
      userToken: $scope.user.token
    };
    userAdventureDetailService.removeFromUserRadar(adventurePackage).then(function (res) {
      // getCartList();
      $scope.hideLoader();
      item.isRadar = 0;
    }, function (_error) {
      $scope.hideLoader();
    });
  };
  function addToUserRadarList (adventurePackage, item) {
    $scope.showLoader();
    if (adventurePackage.package_id && adventurePackage.package_id !== 'null') {
      userAdventureDetailService.addToUserRadar(adventurePackage).then(function (res) {
        // getCartList();
        $scope.hideLoader();
        item.isRadar = 1;
        var data = {
          category: 'Package AddToRadar',
          action: 'Click',
          gtmLabel: 'Add To Radar'
        };
        $rootScope.$broadcast('addEventToGTM', data);
      }, function (_error) {
        $scope.hideLoader();
      });
    }
    $cookies.put('addToRadarPackageId', null);
  }
  vm.showCouponBox = function () {
    if (vm.openCouponBox) {
      vm.openCouponBox = false;
    } else {
      vm.errormsg = '';
      vm.openCouponBox = true;
    }
  };
  // open addons popup
  vm.showAddons = function (addons) {
    vm.currnetselectedAddons = addons;
  };

  vm.applyCoupon = function (coupon, callback) {
    vm.errormsg = '';
    if (vm.previousPrice) { return; }
    if (!coupon || coupon === '') {
      vm.errormsg = MESSAGE_HELPER.coupon.empty_error;
    } else {
      if (vm.CartFullTotal <= 0) {
        return false;
      }
      $scope.showLoader();

      var couponData = {
        'code': coupon,
        'cart_id': cartId,
        'total_amount': vm.promoCodeGrandTotal,
        'currency_code': $scope.currencyCodeForMyCard
      };
      userAdventureDetailService.applyCoupon(couponData).then(function (res) {
        if (res.data.message === 'Success') {
          vm.couponBox = '';
          vm.appliedCouponDetails = res.data;
          vm.appliedCoupon = coupon;
          vm.openCouponBox = false;
          if (vm.appliedCouponDetails.is_applied === 1 || vm.appliedCouponDetails.is_genric === 1) {
            getCartList(false, callback);
            saveCookie('couponApplied', coupon);	// save coupon cookie for 20 min...
            saveCookie('coupon_id', vm.appliedCouponDetails.promo_code_id);
          } else {
            vm.openCouponBox = true;
            vm.couponBox = coupon;
            vm.errormsg = 'Not Applicable.';
            $cookies.put('couponApplied', '');
            $cookies.put('coupon_id', '');
            $scope.hideLoader();
          }
        } else if (res.data.message === 'Resource not found.') {
          if (res.data.date_expire_error === 1) {
            vm.errormsg = MESSAGE_HELPER.coupon.date_expire_error;
          } else if (res.data.my_limit_error === 1) {
            vm.errormsg = MESSAGE_HELPER.coupon.my_limit_error;
          } else if (res.data.user_limit_error === 1) {
            vm.errormsg = MESSAGE_HELPER.coupon.user_limit_error;
          } else if (res.data.amount_minimum_error === 1) {
            vm.errormsg = MESSAGE_HELPER.coupon.amount_minimum_error;
          } else {
            vm.errormsg = MESSAGE_HELPER.coupon.invalid_error;
          }
          $scope.hideLoader();
        }
      }, function (_error) {
        $scope.hideLoader();
        $scope.showPopup('#serverErrModal');
      });
    }
  };
  vm.removeCoupon = function (couponData, callback) {
    if (couponData && !couponData.promo_code_id) {
      return false;
    }
    if (!couponData || (couponData && !couponData.userLoggedOut)) { $scope.showLoader(); }
    var previousPrice = couponData ? couponData.previousPrice : vm.previousPrice;
    var data = {
      'promo_code_id': couponData ? couponData.promo_code_id : vm.appliedCouponDetails.promo_code_id,
      'cart_id': couponData ? couponData.cart_id : cartId,
      'base_amount': previousPrice,
      'total_amount': previousPrice + vm.taxAmount1
    };
    userAdventureDetailService.removeCoupon(data, token).then(function (res) {
      if (res.data.message = 'Success') {
        vm.couponBox = '';
        vm.previousPrice = 0;
        if (!callback) {
          if (!couponData || (couponData && !couponData.userLoggedOut)) { getCartList(); }
          $cookies.put('couponApplied', '');
          $cookies.put('coupon_id', '');
        } else {
          callback();
        }
        vm.appliedCouponDetails = {};
      }
    }, function () {

    });
  };
  $rootScope.CartDetails = [];
  $rootScope.usdRate = 0;
  var usdRate;
  vm.proceedToCheckout = function () {
    if (!vm.isUserLoggedIn) {
      $rootScope.redirectAfterRegister = $location.path();
      $state.go('signin');
    } else {
      $scope.showLoader();
      checkEmailVerificationAndCheckout(); // check email is verified or not then checkout
    }
  };

  // check email is verified or not then checkout
  function checkEmailVerificationAndCheckout () {
    $scope.showLoader();
    // check if user email is verified...
    userRegisterService.isEmailVerified(token).then(function (res) {
      if (!res.data) {
        vm.proceedToCheckout();
      }
      if (res.data && res.data.code === '200') {
        $scope.user.is_email_verified = 1;
        $('.alert-success').css('display', 'none');
        $scope.setUserDetails($scope.user, 'second', true);
        if (vm.anyItemOutOfStock) {
          vm.showAlertMsg = true;
          $scope.hideLoader();
          return;
        }

        $rootScope.operatorAdventureCurrencyCode = $rootScope.operatorAdventureCurrencyCode ? $rootScope.operatorAdventureCurrencyCode : vm.myCart.cart_slot_details[0].package_currency_code;

        // get the usd rate against to user selected currency.
        userAdventureDetailService.convertCurrency(1, CONST_HELPER.payment_const.default_currency, $rootScope.operatorAdventureCurrencyCode).then(function (ress) {
          usdRate = ress.data.usd_rate;
          $rootScope.usdRate = usdRate;
          $rootScope.CartDetails = bookingService.createCartDetailsObject({
            cartSlotDetails: vm.myCart.cart_slot_details,
            usdRate: $rootScope.usdRate,
            Ffixed: CONST_HELPER.PROCESS_FEE.Ffixed,
            Fpercent: CONST_HELPER.PROCESS_FEE.Fpercent,
            siteInIFrame: $rootScope.siteInIFrame,
            isSubDomain: $rootScope.isSubDomain,
            isManualBooking: false
          });
          var cartData = {
            'promo_code_id': vm.appliedCouponDetails.promo_code_id ? vm.appliedCouponDetails.promo_code_id : '',
            'cart_id': cartId,
            'total_amount': vm.CartGrandTotal,
            'base_amount': vm.CartFullTotal,
            user_currency: $rootScope.selectedCurrencyCode,
            deposits: $rootScope.depositPackages
          };
          // saving final cart value and proceed to checkout.
          userAdventureDetailService.proceedToCheckout(cartData, token)
            .then(function (res) {
              if (res.data.message === 'Success') {
                // google analytics code
                var GAdata = {
                  cart_id: cartId.toString(),
                  total_amount: vm.CartGrandTotal.toString(),
                  tax_amount: vm.total_Cart_tax.toString(),
                  currency: $rootScope.selectedCurrencyCode,
                  actionField: { 'step': 3, 'option': 'Proceed To Checkout' },	// optional
                  event_type: 'proceedToCheckout',
                  event: 'gtm.heli.proceedtocheckout'
                };
                $rootScope.$broadcast('addEventToGTM', GAdata); 	// add event to google tag manager.

                // Enhance Ecommerce data
                // google analytics code
                var GCdata = {
                  items: vm.myCart.cart_slot_details,
                  actionField: { 'step': 3, 'option': 'Proceed To Checkout' },
                  event_type: 'proceedToCheckout',
                  event: 'gtm.heli.proceedtocheckout'
                };

                let tagGCData = {
                  currency: 'USD',
                  value:  res.data.google_analytics.usd_total_amount,
                  items: [
                    {
                      item_id: res.data.google_analytics.booking_id,
                      item_name: res.data.google_analytics.package_name,
                      affiliation: res.data.google_analytics.operator_name,
                      price: res.data.google_analytics.usd_total_amount,
                      quantity: 1
                    }
                  ]
                };
                $rootScope.$broadcast('addEventToEC', GCdata); 	// add event to google Analytics.

                $rootScope.$broadcast('googleTagManagerProceedToCheckout', tagGCData);
              } else if (res.data.code === '422') {		// unprocessable intity
                $scope.showPopup('#unprocessableCart');
              }
              $scope.hideLoader();
            })
            .then(function() {
              var data = {
                cart_id: cartId,
                total_amount: vm.CartGrandTotal,
                slot_detail: getSlotDetails(vm.myCart.cart_slot_details),
                local_currency_code: $rootScope.selectedCurrencyCode
              };
              userAdventureDetailService.updateTotalAmount(data, token).then(function (res) {
                // after updating Amount logic goes here...
                $rootScope.doNotReleaseSeat = false;
                $cookies.put('canCheckout', 1);
                $cookies.put('cartDetails', JSON.stringify($rootScope.CartDetails));
                $cookies.put('usdRate', $rootScope.usdRate);
                $cookies.put('selectedCurrencyCode', $rootScope.selectedCurrencyCode);
                $state.go('secure-checkout');
              }, function (error) {
                if (String(error.data.code) === CONST_HELPER.API_RESPONSE.NOT_FOUND) {
                  $scope.showPopup('#unprocessableCart');
                  $scope.hideLoader();
                }
              });
            });
        });
      } else {						// if email is not verified.
        $scope.hideLoader();
        $rootScope.emailSent = false;
        $('.alert-success').css('display', 'block');
      }
    }, function (_error) {
      $scope.hideLoader();
      $scope.showPopup('#serverErrModal');
    });
  }

  // calculate slot amount
  function calculateSlotAmount (item, usdRate) {
    if (usdRate) {
      if (item.amount_percentage) {
        return Number((((item.base_amount - item.discount_amount - item.nightly_discount) * item.package.amount_percentage / 100) / usdRate));
      } else {
        return Number(((item.base_amount - item.discount_amount - item.nightly_discount) / usdRate));
      }
    } else {
      if (item.amount_percentage) {
        return Number(((item.base_amount - item.discount_amount - item.nightly_discount) * item.package.amount_percentage / 100));
      } else {
        return Number((item.base_amount - item.discount_amount - item.nightly_discount));
      }
    }
  }
  // calculate due amount
  function returnDueAmount (item, USD) {
    if (item.package.is_partial_checked) {
      var due;
      if (item.is_promo_code === 1 && item.is_genric === 0) {
        due = Number((item.total - item.package.partial_amount));
      } else {
        due = Number((item.total - item.discount_amount - item.package.partial_amount));
      }
      if (USD === CONST_HELPER.payment_const.default_currency) {
        var totalDue = Number((due / usdRate));
      } else {
        var totalDue = Number(due);
      }
      return totalDue > CONST_HELPER.ZERO ? totalDue : CONST_HELPER.ZERO;
    } else {
      return 0;
    }
  }

  var totalProcessingFee = 0;
  function calculateProcessingFee (item, currency) {		// calculate total processing fee for each package partial/full payment.
    totalProcessingFee = 0;
    if (item.package.is_partial_checked) {
      if (item.is_promo_code === 1 && item.is_genric === 0) {
        var dueAmount = item.total - item.package.partial_amount - item.nightly_discount;
      } else {
        var dueAmount = item.total - item.discount_amount - item.package.partial_amount - item.nightly_discount;
      }
      var payingAmount = item.package.partial_amount - item.nightly_discount;
      calculate(payingAmount, item.tax_amount);
      calculate(dueAmount, item.total_tax - item.tax_amount);
    } else {
      calculate(item.base_amount - item.discount_amount - item.nightly_discount, item.total_tax);
    }
    if (currency === CONST_HELPER.payment_const.default_currency) {
      return Number((totalProcessingFee / usdRate));
    } else {
      return Number(totalProcessingFee);
    }
  }
  function calculateEach (item, USD) {		// calculate processing fee for each package.
    var eachProcessingFee = 0;
    if (item.package.is_partial_checked) {
      eachProcessingFee = Number(item.package.partial_amount) > 0 ? (item.package.partial_amount + item.tax_amount + vm.Ffixed) / (1 - vm.Fpercent) : 0;
      eachProcessingFee = eachProcessingFee - item.package.partial_amount - item.tax_amount;
    } else {
      var newTotal = Number((item.base_amount - item.discount_amount - item.nightly_discount));
      eachProcessingFee = newTotal > 0 ? (item.base_amount - item.discount_amount - item.nightly_discount + item.total_tax + vm.Ffixed) / (1 - vm.Fpercent) : 0;
      eachProcessingFee = eachProcessingFee - newTotal - item.total_tax;
    }
    if (USD === CONST_HELPER.payment_const.default_currency) {
      eachProcessingFee = eachProcessingFee / usdRate;
      return Number(eachProcessingFee);
    } else {
      return Number(eachProcessingFee);
    }
  }
  function calculate (totalAmount, totalTax) {		// calculate processing fee.
    totalAmount = Number(totalAmount);
    totalProcessingFee = totalAmount > 0 ? totalProcessingFee + ((totalAmount + totalTax) * vm.Fpercent) : totalProcessingFee + 0;
    totalProcessingFee = totalProcessingFee;
    return Number(totalProcessingFee);
  }

  function saveCookie (key, val, exp) {
    $cookies.put(key, val, { expires: exp });
  }
  function getSlotDetails (slot) {
    var arr = [];
    slot.map(function (slotdata) {
      var obj = {
        id: slotdata.id,
        total_amount: Number((slotdata.total + slotdata.total_tax).toFixed(2))
      };
      arr.push(obj);
    });
    return arr;
  }

  $rootScope.$on('user.logout', function (e, couponData) {
    couponData.userLoggedOut = true;
    vm.removeCoupon(couponData);
  });

  vm.proceedGuestDetail = function () {
    if(!vm.isUserLoggedIn){
      $rootScope.redirectAfterRegister = $location.path();
      $state.go('signin');
    }else{
      if (!$scope.user.country_id) {
        $scope.showPopup('#emptyUserLocalCountryError');
        return;
      }
      $rootScope.partialCheckedSlot = $scope.partialCheckedSlot;
			$rootScope.depositPackages = $scope.depositPackages;
      $state.go('guest-details');
    }
  }

  $scope.saveLocalCountryMyCart = function () {
    var data = {
      country_id: $scope.userCountry.selectedCountryId,
    }
    $scope.showLoader();
    userRegisterService.saveUserCountry(data, $rootScope.user.id, $rootScope.user.token).then(function (res) {

      $rootScope.partialCheckedSlot = $scope.partialCheckedSlot;
			$rootScope.depositPackages = $scope.depositPackages;
      $rootScope.userLocalCountry = $scope.userCountry.selectedCountryId;
      $rootScope.user = JSON.parse($cookies.get('user'));
      $rootScope.user.country_id = $scope.userCountry.selectedCountryId;
      $scope.user.country_id = $scope.userCountry.selectedCountryId;
      $cookies.put('user', JSON.stringify($rootScope.user));// set local storage again
      $cookies.put('user_country_id', JSON.stringify($scope.userCountry.selectedCountryId));
      $scope.hidePopup('#emptyUserLocalCountryError');
      $scope.hideLoader();
      $state.go('guest-details');
    })
  }

  $scope.clearAllFieldsForGuest = function (count, value) {
    if (value) {
      $scope.guestDetailsInformationError = false;
      for (var keyField in $scope.guests_detail[count]) {
        if(keyField != 'guest_tbo'){
          $scope.guests_detail[count][keyField] = '';
        }
      }
    } else {
      $scope.guests_detail[count]['guest_adult_child'] = 1;
      $scope.guests_detail[count]['guest_tbo'] = 0;
    }
  }

  function validateGuestsInformation () {
    $scope.guestsDetailInfoError = [];
    !$scope.generalGuest.first_name ? $scope.generalGuestFirstNameError = true : $scope.generalGuestFirstNameError = false;
    !$scope.generalGuest.last_name ? $scope.generalGuestLastNameError = true : $scope.generalGuestLastNameError = false;
    !$scope.generalGuest.email || !$scope.generalGuest.email.match($scope.emailPattern) ? $scope.generalGuestEmailError = true : $scope.generalGuestEmailError = false;
    var phoneNumberValue = $("#generalGuestPhone").intlTelInput("getNumber");
    if (phoneNumberValue == "" || phoneNumberValue.length < 8 || phoneNumberValue.length > 16) {
      $scope.generalGuestPhoneError = true;
    } else {
      $scope.generalGuestPhoneError = false;
    };
    $scope.guestDetailsInformationError = false;

    $scope.guests_detail.forEach(function (eachGuest) {
      var errorsInfo = {};
      if (!eachGuest.guest_tbo) {
        if (!eachGuest.guest_email || !eachGuest.guest_email.match($scope.emailPattern)) {
          errorsInfo.email_error = true;
          $scope.guestDetailsInformationError = true;
        } else {
          errorsInfo.email_error = false;
        }
        if (!eachGuest.guest_adult_child) {
          errorsInfo.adult_child_error = true;
          $scope.guestDetailsInformationError = true;
        } else {
          errorsInfo.adult_child_error = false;
        }
        if (!eachGuest.guest_name) {
          errorsInfo.name_error = true;
          $scope.guestDetailsInformationError = true;
        } else {
          errorsInfo.name_error = false;
        }
        errorsInfo.guest_allergies = false;
        errorsInfo.guest_additional_information = false;
        $scope.guestsDetailInfoError.push(errorsInfo)
      } else {
        errorsInfo.email_error = false;
        errorsInfo.adult_child_error = false;
        errorsInfo.name_error = false;
        errorsInfo.guest_allergies = false;
        errorsInfo.guest_additional_information = false;
        $scope.guestsDetailInfoError.push(errorsInfo);
      }
    });


    if(!$scope.acknowledge_agree){
      $scope.acknowledgeAgreeError = true;
    }
  }

  $scope.goToPayment = function () {
    validateGuestsInformation()
    if(!$scope.guestDetailsInformationError
      && !$scope.acknowledgeAgreeError
      && !$scope.generalGuestFirstNameError
      && !$scope.generalGuestLastNameError
      && !$scope.generalGuestEmailError
      && !$scope.generalGuestPhoneError
    ){
      var phoneNumber = $("#generalGuestPhone").intlTelInput("getNumber");
      if (phoneNumber.substr(0, 1) == '+') {
        phoneNumber = phoneNumber.slice(1);
      }
      $scope.generalGuest.phone_number = phoneNumber;
      $scope.guestDetail.acknowledge_agree = $scope.acknowledge_agree;
      $scope.guestDetail.guests_detail = $scope.guests_detail;
      $scope.guestDetail.generalGuest = $scope.generalGuest;
      $rootScope.guests_details = $scope.guestDetail;
      $cookies.put('guests_details', JSON.stringify($scope.guestDetail));
      vm.proceedToCheckout()
    }else{
      return;
    }
  }
}
