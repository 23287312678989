import { Component, OnInit } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { RootScopeService } from '../../../../../../ng-app/src/app/services/root-scope/root-scope.service';
import { WebService } from '../../../../../../app/scripts/services/webService';

@Component({
  selector: 'app-video-bg-block',
  templateUrl: '/ng-app/src/app/components/home/video-bg-block/video-bg-block.component.html',
  styleUrls: ['../../../../../../ng-app/src/app/components/home/video-bg-block/video-bg-block.component.css']
})
export class VideoBgBlockComponent implements OnInit {

  user: any = {}

  constructor(
    public webService: WebService,
    public router: UIRouter,
    public rootScopeService: RootScopeService
    ) { 
  }

  ngOnInit(): void {
  }

}
