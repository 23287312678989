import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-partners',
  templateUrl: '/ng-app/src/app/components/home/partners/partners.component.html',
  styleUrls: ['../../../../../../ng-app/src/app/components/home/partners/partners.component.css']
})
export class PartnersComponent implements OnInit {

  slickHeliPartnerConfig = {
    enabled: true,
    autoplay: false,
    draggable: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  }

  partners = [
    {
      id: 1,
      link: 'https://www.backcountry.com/?avad=261981_f1900ff09&PubID=208237&PubName=heli.life.',
      logoSrc: 'images/partners-update/backcountry.png'
    },
    {
      id: 2,
      link: 'https://flylowgear.com/',
      logoSrc: 'images/partners-update/flylow.png'
    },
    {
      id: 3,
      link: 'http://www.helicat.org/',
      logoSrc: 'images/partners-update/helicat.png'
    },
    {
      id: 4,
      link: 'https://www.slingshotsports.com/',
      logoSrc: 'images/partners-update/slingshot.jpg'
    },
    {
      id: 5,
      link: 'https://opensnow.com/',
      logoSrc: 'https://heli-production.s3.amazonaws.com/Partner/opensnow_logo_black.png'
    },
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
